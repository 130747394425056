import { useMemo } from 'react';
import { RefCallBack, useFormContext } from 'react-hook-form';
import { PMQuestionnaireItem, pickFirstValueFromAnswerItem } from 'utils';

interface PaperworkFormHelpers {
  fieldId: string;
  value: any;
  inputRef: RefCallBack;
  onChange: (e: any) => void;
}

export function usePaperworkFormHelpers(
  item: PMQuestionnaireItem,
  renderValue: any,
  renderOnChange: (e: any) => void
): PaperworkFormHelpers {
  const { register } = useFormContext();
  return useMemo(() => {
    const stringValTypes = ['text', 'choice', 'open-choice', 'string'];
    const value = (() => {
      if (stringValTypes.includes(item.type)) {
        return pickFirstValueFromAnswerItem(renderValue) ?? '';
      }
      if (item.type === 'boolean') {
        return pickFirstValueFromAnswerItem(renderValue, 'boolean') ?? false;
      }
      if (item.type === 'attachment') {
        return pickFirstValueFromAnswerItem(renderValue, 'attachment');
      }
      return undefined;
    })();
    const onChange = (e: any): void => {
      const base = { linkId: item.linkId };
      if (stringValTypes.includes(item.type)) {
        return renderOnChange({ ...base, answer: [{ valueString: e.target.value.trimStart() }] });
      } else if (item.type === 'boolean') {
        return renderOnChange({ ...base, answer: [{ valueBoolean: !(value ?? false) }] });
      } else if (item.type === 'attachment') {
        // the file upload component will give us the attachment directly; de don't pull it from an event
        renderOnChange({ ...base, answer: [{ valueAttachment: e }] });
      }
    };
    const { inputRef, fieldId } = (() => {
      let valueTypeString = stringValTypes.includes(item.type) ? 'valueString' : '';
      if (item.type === 'boolean') {
        valueTypeString = 'valueBoolean';
      }
      if (item.type === 'attachment') {
        valueTypeString = 'valueAttachment';
      }
      const fieldId = `${item.linkId}.answer.0.${valueTypeString}`;
      return { inputRef: register(`${item.linkId}.answer.0.${valueTypeString}`).ref, fieldId };
    })();
    return { value, onChange, inputRef, fieldId };
  }, [item.type, register, item.linkId, renderValue, renderOnChange]);
}
