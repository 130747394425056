import { EditOutlined } from '@mui/icons-material';
import { IconButton, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ErrorDialog, ErrorDialogConfig, getValueBoolean, PageForm, ReviewItem } from 'ui-components';
import { DATETIME_FULL_NO_YEAR, VisitType, makeValidationSchema, uuidRegex, pickFirstValueFromAnswerItem } from 'utils';
import { IntakeFlowPageRoute } from '../App';
import { otherColors } from '../IntakeThemeProvider';
import { UCContainer } from '../components';
import { useTrackMixpanelEvents } from '../hooks/useTrackMixpanelEvents';
import { useSetLastActiveTime } from '../hooks/useSetLastActiveTime';
import mixpanel from 'mixpanel-browser';
import { slugFromLinkId, usePaperworkContext } from './PaperworkPage';
import { appointmentNotFoundInformation } from '../helpers/information';
import { ZambdaClient, useUCZambdaClient } from '../hooks/useUCZambdaClient';
import { useGetFullName } from '../hooks/useGetFullName';
import api from '../api/zapehrApi';
import { ValidationError } from 'yup';
import { QuestionnaireResponseItem } from 'fhir/r4';
import { FormValidationErrorObject, getFormValidationErrors } from '../api/errorHelpers';
import ValidationErrorMessageContent from '../features/paperwork/components/ValidationErrorMessage';
import { UNEXPECTED_ERROR_CONFIG } from '../helpers';

const ReviewPaperwork = (): JSX.Element => {
  const navigate = useNavigate();
  const { id: appointmentID } = useParams();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [appointmentNotFound, setAppointmentNotFound] = useState<boolean>(false);
  const zambdaClient = useUCZambdaClient({ tokenless: true });
  const [validationErrors, setValidationErrors] = useState<FormValidationErrorObject | undefined>();
  const [errorDialogConfig, setErrorDialogConfig] = useState<ErrorDialogConfig | undefined>(undefined);

  const {
    appointment: appointmentData,
    patient: patientInfo,
    paperwork: completedPaperwork,
    pages: paperworkPages,
    findAnswerWithLinkId,
    allItems,
  } = usePaperworkContext();

  const selectedLocation = useMemo(() => {
    return appointmentData?.location;
  }, [appointmentData?.location]);
  const visitType = useMemo(() => {
    return appointmentData?.visitType;
  }, [appointmentData?.visitType]);

  const patientFullName = useGetFullName(patientInfo);

  const selectedSlotTimezoneAdjusted = useMemo(() => {
    const selectedAppointmentStart = appointmentData?.start;
    const timezone = appointmentData?.location.timezone ?? 'America/New_York';
    if (selectedAppointmentStart) {
      return DateTime.fromISO(selectedAppointmentStart).setZone(timezone).setLocale('en-us');
    }

    return undefined;
  }, [appointmentData?.start, appointmentData?.location?.timezone]);

  useTrackMixpanelEvents({
    eventName: 'Review Paperwork',
    visitType: visitType,
    bookingCity: selectedLocation?.address?.city,
    bookingState: selectedLocation?.address?.state,
  });

  // Update last active time for paperwork-in-progress flag every minute
  useSetLastActiveTime(appointmentID, true, zambdaClient);

  useEffect(() => {
    try {
      let errorMessage;
      if (!appointmentID) {
        errorMessage = `appointment ID missing`;
        setAppointmentNotFound(true);
      } else {
        const isUuid = appointmentID.match(uuidRegex);
        if (!isUuid) {
          errorMessage = `appointment ID is not a valid UUID`;
          setAppointmentNotFound(true);
        }
      }

      if (errorMessage) {
        throw new Error(`${errorMessage}. path: ${pathname}`);
      }
    } catch (error) {
      console.error(error);
    }
  }, [appointmentID, pathname]);

  const { paperworkCompletedStatus, allComplete } = useMemo(() => {
    const validationSchema = makeValidationSchema(allItems);
    const validationState = (paperworkPages ?? []).reduce(
      (accum, page) => {
        accum[page.linkId] = true;
        return accum;
      },
      {} as { [pageLinkId: string]: boolean }
    );

    try {
      console.log('completed papwerwork', completedPaperwork);
      validationSchema.validateSync(completedPaperwork, { abortEarly: false });
    } catch (e) {
      const errorList =
        (e as ValidationError).inner?.map((item) => {
          return item.path?.split('.')?.[0];
        }) ?? [];
      console.log('errorList', errorList, e);
      const pagesWithError = (paperworkPages ?? [])
        .filter((page) => {
          const containsError = errorList.some((errorId) => {
            return page.item?.some((item) => {
              return item.linkId === errorId;
            });
          });
          return containsError;
        })
        .map((page) => page.linkId);
      pagesWithError.forEach((pageId) => {
        if (validationState[pageId] !== undefined) {
          validationState[pageId] = false;
        }
      });
      console.log('pagesWithError', pagesWithError);
    }
    const photoIdFront = pickFirstValueFromAnswerItem(findAnswerWithLinkId('photo-id-front'), 'attachment');
    console.log('photoIdFront', photoIdFront, findAnswerWithLinkId('photo-id-front'));
    // this is a strange one-off; it is optional in the schema but we communicate to the user that it is required
    if (photoIdFront === undefined) {
      validationState['photo-id-page'] = false;
    }
    const allComplete = Object.values(validationState).some((val) => !val) === false;
    return { paperworkCompletedStatus: validationState, allComplete };
  }, [allItems, completedPaperwork, findAnswerWithLinkId, paperworkPages]);

  const reviewItems: ReviewItem[] = [
    {
      name: 'Patient',
      valueString: patientFullName,
      hidden: !patientInfo?.firstName, // users who are not logged in will not see name
    },
    {
      name: 'Office',
      valueString: selectedLocation ? `${selectedLocation?.name}` : 'Unknown',
    },
    {
      name: 'Check-in time',
      valueString: selectedSlotTimezoneAdjusted?.toFormat(DATETIME_FULL_NO_YEAR),
      hidden: visitType === VisitType.WalkIn,
    },
    ...(paperworkPages ?? []).map((paperworkPage) => {
      let hasError = false;
      if (validationErrors) {
        hasError = validationErrors[paperworkPage.linkId]?.length ? true : false;
      }
      return {
        name: paperworkPage.text ?? 'Review',
        path: `/paperwork/${appointmentID}/${slugFromLinkId(paperworkPage.linkId)}`,
        valueBoolean: paperworkCompletedStatus[paperworkPage.linkId] && !hasError,
      };
    }),
  ];

  const submitPaperwork = useCallback(async (): Promise<void> => {
    const submitPaperwork = async (
      data: QuestionnaireResponseItem[],
      aptId: string,
      zambdaClient: ZambdaClient
    ): Promise<void> => {
      await api.submitPaperwork(zambdaClient, { answers: data, appointmentId: aptId });
    };
    if (appointmentID && zambdaClient) {
      try {
        setLoading(true);
        try {
          await submitPaperwork([], appointmentID, zambdaClient);
        } catch (e) {
          const validationErrors = getFormValidationErrors(e);
          if (validationErrors) {
            console.log('validation errors returned: ', validationErrors);
            setValidationErrors(validationErrors);
            const links = paperworkPages.reduce(
              (accum, current) => {
                if (Object.keys(validationErrors).includes(current.linkId)) {
                  accum[current.linkId] = {
                    path: `/paperwork/${appointmentID}/${slugFromLinkId(current.linkId)}`,
                    pageName: current.text ?? current.linkId,
                  };
                }
                return accum;
              },
              {} as { [pageId: string]: { path: string; pageName: string } }
            );
            setErrorDialogConfig({
              title: 'Error Validating Form',
              description: <ValidationErrorMessageContent errorObejct={validationErrors} links={links} />,
            });
          } else {
            setErrorDialogConfig(UNEXPECTED_ERROR_CONFIG);
          }
          return;
        }
        const locationCity = selectedLocation?.address?.city ?? '';
        const locationState = selectedLocation?.address?.state ?? '';
        mixpanel.track('UC Paperwork Submitted', {
          uc_bookingcity_evt: locationCity,
          uc_bookingstate_evt: locationState,
          visit_status_evt: 'Not Complete',
          fhir_visit_id: appointmentID,
          fhir_patient_id: patientInfo?.id,
          patientFlow: visitType,
        });
        if (visitType === VisitType.WalkIn) {
          navigate(`/visit/${appointmentID}/check-in`);
        } else {
          navigate(`/visit/${appointmentID}`);
        }
      } catch (e) {
        // todo: handle this better, direct to page where error was found if available
        console.error('error submitting paperwork', e);
      } finally {
        setLoading(false);
      }
    }
  }, [
    appointmentID,
    zambdaClient,
    selectedLocation?.address?.city,
    selectedLocation?.address?.state,
    patientInfo?.id,
    visitType,
    paperworkPages,
    navigate,
  ]);

  if (appointmentNotFound) {
    return (
      <UCContainer
        title="Appointment is not found"
        description={appointmentNotFoundInformation}
        bgVariant={IntakeFlowPageRoute.PaperworkHomeRoute.path}
      >
        <></>
      </UCContainer>
    );
  }

  return (
    <UCContainer
      title="Review and submit"
      description="Review and confirm all details below."
      bgVariant={IntakeFlowPageRoute.ReviewPaperwork.path}
    >
      <Typography variant="h3" color="primary" marginTop={2} marginBottom={0}>
        Visit details
      </Typography>
      <Table
        sx={{
          marginBottom: 2,
          tr: {
            td: {
              borderBottom: '1px solid #E3E6EF',
            },
            '&:last-child': {
              td: {
                borderBottom: 'none',
              },
            },
          },
        }}
      >
        <TableBody>
          {reviewItems
            .filter((reviewItem) => !reviewItem.hidden)
            .map((reviewItem) => (
              <TableRow key={reviewItem.name}>
                <TableCell
                  sx={{
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 0,
                    paddingRight: 0,
                    color: otherColors.darkPurple,
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                  }}
                >
                  {reviewItem.name === 'Complete consent forms' ? (
                    <span>Consent forms</span>
                  ) : reviewItem.name === 'How would you like to pay for your visit?' ? (
                    <span>Insurance details</span>
                  ) : (
                    <span>{reviewItem.name}</span>
                  )}
                </TableCell>
                <TableCell padding="none" align="right">
                  {reviewItem.valueString !== undefined && reviewItem.valueString}
                  {reviewItem.valueBoolean !== undefined && getValueBoolean(reviewItem.valueBoolean)}
                </TableCell>
                <TableCell padding="none" sx={{ paddingLeft: 1 }}>
                  {reviewItem.path && (
                    <Tooltip title="Edit" placement="right">
                      <Link to={reviewItem.path}>
                        <IconButton aria-label="edit" color="primary">
                          <EditOutlined />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <PageForm
        controlButtons={{
          submitLabel: allComplete ? 'Finish' : 'Save and finish later',
          loading: loading,
        }}
        onSubmit={submitPaperwork}
      />
      <ErrorDialog
        open={!!errorDialogConfig}
        title={errorDialogConfig?.title || 'Error Validating Form'}
        description={errorDialogConfig?.description || ''}
        closeButtonText={'Close'}
        handleClose={() => {
          setErrorDialogConfig(undefined);
        }}
      />
    </UCContainer>
  );
};

export default ReviewPaperwork;
