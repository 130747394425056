import { Box, Button, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { FormEvent, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ControlButtons, ErrorDialog, breakpoints, ErrorDialogConfig } from 'ui-components';
import { DATETIME_FULL_NO_YEAR, DATE_FULL_NO_YEAR, createLocalDateTime } from 'utils';
import { otherColors } from '../IntakeThemeProvider';
import zapehrApi from '../api/zapehrApi';
import { SelectSlot } from '../components';
import { useUCZambdaClient } from '../hooks/useUCZambdaClient';

interface TabPanelProps {
  children?: ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const dialogTitle = 'Sorry, the selected time is no longer available';
const dialogDescription = 'Please select another time';

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`appointment-picker-tabpanel-${index}`}
      aria-labelledby={`appointment-picker-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3, pb: 3 }}>{children}</Box>}
    </div>
  );
};

const tabProps = (
  index: number
): {
  id: string;
  'aria-controls': string;
} => {
  return {
    id: `appointment-picker-tab-${index}`,
    'aria-controls': `appointment-picker-tabpanel-${index}`,
  };
};

interface ScheduleProps {
  slotData: string[] | undefined;
  setSlotData: (slotData: string[]) => void;
  slotsLoading: boolean;
  submitPending?: boolean;
  backButton?: boolean;
  submitLabelAdjective?: string;
  existingSelectedSlot: string | undefined;
  handleSlotSelected: (slot: string) => void;
  timezone: string;
  locationSlug: string | undefined;
  locationState: string | undefined;
  forceClosedToday: boolean;
  forceClosedTomorrow: boolean;
  markSlotBusy: boolean; // rescheduled appointments will always fail if they mark the very slot they're rescheduling to as tentatitvely busy
}

const Schedule = ({
  slotData,
  setSlotData,
  backButton = false,
  slotsLoading,
  submitLabelAdjective = 'Select',
  existingSelectedSlot,
  handleSlotSelected,
  timezone,
  locationSlug,
  locationState,
  forceClosedToday,
  submitPending,
  markSlotBusy,
}: ScheduleProps): JSX.Element => {
  const theme = useTheme();
  const tokenlessZambdaClient = useUCZambdaClient({ tokenless: true });
  const [currentTab, setCurrentTab] = useState(0);
  const [errorDialog, setErrorDialog] = useState<ErrorDialogConfig | undefined>(undefined);
  const [locallySelectedSlot, setLocallySelectedSlot] = useState<string | undefined>(existingSelectedSlot);
  const [slotAvailableCheckPending, setSlotAvailableCheckPending] = useState(false);

  const processingSubmit = useMemo(() => {
    return slotAvailableCheckPending || submitPending;
  }, [slotAvailableCheckPending, submitPending]);

  // todo: don't assume first/second available day are today/tomorrow
  const [firstAvailableDay, secondAvailableDay] = useMemo(() => {
    const nowLocal = createLocalDateTime(DateTime.now(), timezone);
    const firstAvailableDay = nowLocal;
    const secondAvailableDay = nowLocal?.plus({ days: 1 });
    return [firstAvailableDay, secondAvailableDay];
  }, [timezone]);

  // console.log('locallySelectedSlot', locallySelectedSlot);
  useEffect(() => {
    setLocallySelectedSlot(existingSelectedSlot);
  }, [existingSelectedSlot]);

  const hasChosenSlot = locallySelectedSlot !== undefined;

  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    try {
      if (!hasChosenSlot) {
        setErrorDialog({
          title: 'Please select a date and time',
          description: 'To continue, please select an available appointment.',
        });
      } else {
        setSlotAvailableCheckPending(true);
        // if the existing selected slot differs from the locally selected slot
        // or if there is no existing selected slot
        if (locallySelectedSlot !== existingSelectedSlot) {
          // check if slot is available
          if (!tokenlessZambdaClient || !locationSlug || !locationState) {
            return;
          }
          // todo: this check should live in the components that use this component
          // and be part of the slot selection handler passed in
          const res = await zapehrApi.getLocation(tokenlessZambdaClient, {
            locationSlug,
            locationState,
            specificSlot: markSlotBusy ? locallySelectedSlot : undefined,
          });
          if (res.available.includes(locallySelectedSlot)) {
            handleSlotSelected(locallySelectedSlot);
          } else {
            // todo: this error dialog probably shouldn't live in this component; it's doing too much
            setErrorDialog({
              title: dialogTitle,
              description: dialogDescription,
            });
            setSlotData(res.available);
          }
        } else {
          handleSlotSelected(locallySelectedSlot);
        }
        setSlotAvailableCheckPending(false);
      }
    } catch (error) {
      console.log(error);
      setSlotAvailableCheckPending(false);
    }
  };

  const [slotsList, firstAvailableSlot, daySlotsMap] = useMemo(() => {
    if (slotData) {
      const slots = [...slotData];

      // This maps days to an array of slots
      const map: { [ord: number]: string[] } = slots.reduce(
        (accumulator, current) => {
          const dateOfCurrent = DateTime.fromISO(current, { zone: timezone });
          const existing = accumulator[dateOfCurrent.ordinal];
          if (existing) {
            existing.push(current);
          } else {
            accumulator[dateOfCurrent.ordinal] = [current];
          }
          return accumulator;
        },
        {} as { [ord: number]: string[] }
      );

      return [slots, slots[0], map];
    }
    return [[], '', {}];
  }, [timezone, slotData]);

  const isFirstAppointment = useMemo(() => {
    return slotsList && slotsList[0] ? locallySelectedSlot === slotsList[0] : false;
  }, [slotsList, locallySelectedSlot]);

  const handleChange = (_: SyntheticEvent, newCurrentTab: number): void => {
    setCurrentTab(newCurrentTab);
  };

  const selectedDate = useMemo(() => {
    if (currentTab === 0) {
      return firstAvailableDay;
    } else if (currentTab === 1) {
      return secondAvailableDay;
    } else {
      return undefined;
    }
  }, [currentTab, firstAvailableDay, secondAvailableDay]);

  const selectedSlotTimezoneAdjusted = useMemo(() => {
    const selectedAppointmentStart = locallySelectedSlot;
    if (selectedAppointmentStart) {
      return createLocalDateTime(DateTime.fromISO(selectedAppointmentStart), timezone);
    }

    return undefined;
  }, [locallySelectedSlot, timezone]);

  const getSlotsForDate = useCallback(
    (date: DateTime | undefined): string[] => {
      if (date === undefined) {
        return [];
      }
      return daySlotsMap[date.ordinal] ?? [];
    },
    [daySlotsMap]
  );

  const [firstAvailableDaySlots, secondAvailableDaySlots, slotsExist] = useMemo(() => {
    const firstAvailableDaySlots = getSlotsForDate(firstAvailableDay);
    const secondAvailableDaySlots = getSlotsForDate(secondAvailableDay);
    const slotsExist = firstAvailableDaySlots.length || secondAvailableDaySlots.length;
    return [firstAvailableDaySlots, secondAvailableDaySlots, slotsExist];
  }, [firstAvailableDay, getSlotsForDate, secondAvailableDay]);

  const officeClosedTodayMessage =
    'So sorry! Due to unforeseen circumstances, this office is closed today. Check out our convenient telemedicine option to connect with a PM pediatric expert from the comfort of home OR click the "tomorrow" button to view available slots for tomorrow.';
  const officeClosedTodayTomorrowMessage =
    "So sorry! This office does not have any available prebook times today or tomorrow, but we've got you covered! Check out our convenient telemedicine option to connect with a PM pediatric expert from the comfort of home.";

  // Cause TS thinks breakpoints.values may be undefined and won't let me use a non-null assertion.
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.values?.sm}px)`);

  // console.log('office is closed today, tomorrow', forceClosedToday, forceClosedTomorrow);
  // console.log('slots today, slots tomorrow', slotsToday, forceClosedTomorrow);

  const { firstTabVisible, secondTabVisible } = useMemo(() => {
    const firstTabVisible = firstAvailableDaySlots.length > 0 || forceClosedToday;
    const secondTabVisible = secondAvailableDaySlots.length > 0;
    return { firstTabVisible, secondTabVisible };
  }, [firstAvailableDaySlots.length, forceClosedToday, secondAvailableDaySlots.length]);

  /*
  console.log('firstTabVisible', firstTabVisible);
  console.log('secondTabVisible', secondTabVisible);
  console.log('secondAvailableDaySlots', JSON.stringify(secondAvailableDaySlots));
  console.log('secondAvailableDay', secondAvailableDay, secondAvailableDay?.ordinal);
  console.log('daySlotsMap', JSON.stringify(daySlotsMap));
  console.log('timezone', timezone);
*/

  const showControlButtons = useMemo(() => {
    if (currentTab === 0 && slotsExist) {
      return true;
    } else if (currentTab === 1 && secondAvailableDaySlots.length) {
      return true;
    }
    return false;
  }, [currentTab, secondAvailableDaySlots.length, slotsExist]);

  return (
    <>
      <form onSubmit={(e) => onSubmit(e)}>
        <Box
          sx={{
            display: firstTabVisible || secondTabVisible ? 'flex' : 'none',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', md: 'center' },
            mt: 3,
          }}
        >
          <Typography variant="h3" color="primary">
            Select check-in date and time
          </Typography>
          {selectedDate?.offsetNameShort && (
            <Typography color="theme.palette.text.secondary" sx={{ pt: { xs: 1.5, md: 0.5 } }}>
              Time Zone: {selectedDate?.offsetNameShort}
            </Typography>
          )}
        </Box>
        {firstAvailableSlot && selectedDate != undefined && DateTime.fromISO(firstAvailableSlot).isValid ? (
          <Button
            variant={isFirstAppointment ? 'contained' : 'outlined'}
            sx={{
              color: isFirstAppointment ? theme.palette.primary.contrastText : theme.palette.text.primary,
              border: isFirstAppointment
                ? `1px solid ${theme.palette.primary.main}`
                : `1px solid ${theme.palette.divider}`,
              p: 1,
              borderRadius: '8px',
              textAlign: 'center',
              mt: 2.5,
              mb: 1.5,
              width: '100%',
              fontWeight: 400,
              display: { xs: 'block', md: 'inline' },
            }}
            onClick={() => handleSlotSelected(firstAvailableSlot)}
            type="button"
            className="first-button"
          >
            <span style={{ fontWeight: 700 }}>First available time:&nbsp;</span>
            {isMobile && <br />}
            {DateTime.fromISO(firstAvailableSlot).setZone(timezone).toFormat(DATETIME_FULL_NO_YEAR)}
          </Button>
        ) : slotsExist ? (
          <Box
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              p: 1,
              borderRadius: '8px',
              textAlign: 'center',
              mt: 1,
              display: slotsExist ? 'inherit' : 'none',
            }}
          >
            <Typography variant="body2">Calculating...</Typography>
          </Box>
        ) : (
          <></>
        )}
        {!slotsLoading ? (
          <Box>
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={currentTab}
                onChange={firstTabVisible && secondTabVisible ? handleChange : undefined}
                TabIndicatorProps={{
                  style: {
                    background: otherColors.borderLightBlue,
                    height: '5px',
                    borderRadius: '2.5px',
                  },
                }}
                textColor="inherit"
                variant="fullWidth"
                aria-label="Appointment tabs for switching between appointments slots for today and tomorrow"
              >
                {firstTabVisible && (
                  <Tab
                    label="Today"
                    {...tabProps(0)}
                    sx={{
                      color:
                        currentTab == 0 && firstTabVisible
                          ? theme.palette.secondary.main
                          : theme.palette.text.secondary,
                      opacity: 1,
                    }}
                  />
                )}
                {secondTabVisible && ( // office is closed today and open tomorrow
                  <Tab
                    label="Tomorrow"
                    {...tabProps(firstTabVisible ? 1 : 0)}
                    sx={{
                      color:
                        currentTab == 1 || !firstTabVisible
                          ? theme.palette.secondary.main
                          : theme.palette.text.secondary,
                      opacity: 1,
                    }}
                  />
                )}
              </Tabs>
            </Box>
            <Box>
              {firstTabVisible && (
                <TabPanel value={currentTab} index={0} dir={theme.direction}>
                  <Typography variant="h3" color="#000000" sx={{ textAlign: 'center' }}>
                    {firstAvailableDay?.toFormat(DATE_FULL_NO_YEAR)}
                  </Typography>
                  <SelectSlot
                    slots={firstAvailableDaySlots}
                    currentTab={currentTab}
                    timezone={timezone}
                    currentSelectedSlot={locallySelectedSlot}
                    handleSlotSelected={setLocallySelectedSlot}
                    noSlotsMessage={slotsExist ? officeClosedTodayMessage : officeClosedTodayTomorrowMessage}
                  />
                </TabPanel>
              )}
              {secondTabVisible && (
                <TabPanel value={currentTab} index={firstTabVisible ? 1 : 0} dir={theme.direction}>
                  <Typography variant="h3" color="#000000" sx={{ textAlign: 'center' }}>
                    {secondAvailableDay?.toFormat(DATE_FULL_NO_YEAR)}
                  </Typography>
                  <SelectSlot
                    slots={secondAvailableDaySlots}
                    currentTab={currentTab}
                    timezone={timezone}
                    currentSelectedSlot={locallySelectedSlot}
                    handleSlotSelected={setLocallySelectedSlot}
                  />
                </TabPanel>
              )}
              {!firstTabVisible && !secondTabVisible && (
                <TabPanel value={currentTab} index={0} dir={theme.direction}>
                  <Typography variant="h3" color="#000000" sx={{ textAlign: 'center' }}>
                    {firstAvailableDay?.toFormat(DATE_FULL_NO_YEAR)} - {secondAvailableDay?.toFormat(DATE_FULL_NO_YEAR)}
                  </Typography>
                  <SelectSlot
                    slots={[]}
                    currentTab={currentTab}
                    timezone={timezone}
                    currentSelectedSlot={undefined}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    handleSlotSelected={() => {}}
                    noSlotsMessage={officeClosedTodayTomorrowMessage}
                  />
                </TabPanel>
              )}
            </Box>
          </Box>
        ) : (
          <Typography variant="body2" m={1} textAlign={'center'}>
            Loading...
          </Typography>
        )}

        {showControlButtons && (
          <ControlButtons
            loading={processingSubmit}
            backButton={backButton}
            submitLabel={
              hasChosenSlot && selectedSlotTimezoneAdjusted !== undefined
                ? `${submitLabelAdjective} ${selectedSlotTimezoneAdjusted?.toFormat(DATETIME_FULL_NO_YEAR)}`
                : 'Select time'
            }
          />
        )}
      </form>
      <ErrorDialog
        open={!!errorDialog}
        title={errorDialog?.title || ''}
        description={errorDialog?.description || ''}
        closeButtonText={errorDialog?.closeButtonText ?? 'Select another time'}
        handleClose={() => {
          setErrorDialog(undefined);
        }}
      />
    </>
  );
};

export default Schedule;
