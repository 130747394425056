import { PMP_MODULE } from '../fhir';

export const INSURANCE_PLAN_PAYER_META_TAG_CODE = 'insurance-payer-plan';
export const TELEMED_VIDEO_ROOM_CODE = 'chime-video-meetings';
export const ELIGIBILITY_BENEFIT_CODES = 'UC,86,30';
export const ELIGIBILITY_FAILED_REASON_META_TAG = `${PMP_MODULE.TM}-eligibility-failed-reason`;
export const ELIGIBILITY_PRACTITIONER_META_TAG_PREFIX = `${PMP_MODULE.TM}-eligibility-billing-provider`;
export const ELIGIBILITY_RELATED_PERSON_META_TAG = `${PMP_MODULE.TM}-eligibility-related-person`;
export const BYPASS_INSURANCE_NAME = 'zz admin';

export const ELIGIBILITY_FAILED_REASONS = {
  apiFailure: 'api-failure',
  eligibilityCheckDisabled: 'eligibility-check-disabled',
  realTimeEligibilityUnsupported: 'real-time-eligibility-unsupported',
};
export const ELIGIBILITY_PRACTITIONER_TYPES = ['individual', 'group'] as const;
export type EligibilityPractitionerType = (typeof ELIGIBILITY_PRACTITIONER_TYPES)[number];

export const SELF_PAY_CODING = {
  system: 'http://terminology.hl7.org/CodeSystem/coverage-selfpay',
  code: 'pay',
};
