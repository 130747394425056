import { ErrorDialogConfig, UnexpectedErrorDescription } from 'ui-components';

export const MINIMUM_AGE = 5;
export const MAXIMUM_AGE = 26;
export const PM_PEDIATRICS_WEBSITE = 'https://pmpediatriccare.com/';
export const parentAndPatientOption = [
  {
    label: 'Parent/Guardian',
    value: 'Parent/Guardian',
  },
  {
    label: 'Patient',
    value: 'Patient (Self)',
  },
];

export enum FillingOutAsValue {
  ParentGuardian = 'Parent/Guardian',
  Patient = 'Patient (Self)',
  Other = 'Other',
}

export const fillingThisOutAsOptions: {
  label: string;
  value: FillingOutAsValue;
}[] = [
  {
    label: 'I am the Parent or legal guardian of the minor patient',
    value: FillingOutAsValue.ParentGuardian,
  },
  {
    label: 'I am the Patient',
    value: FillingOutAsValue.Patient,
  },
  {
    label: 'I am NOT the parent or legal guardian but have permission to bring the minor patient for care',
    value: FillingOutAsValue.Other,
  },
];

export const parentOption = [
  {
    label: 'Parent/Guardian',
    value: 'Parent/Guardian',
  },
];

export const NOT_PATIENT_OR_GUARDIAN_ERROR: ErrorDialogConfig = {
  title: '',
  description: 'Please proceed to the front desk to check-in and complete patient paperwork',
  closeButtonText: 'OK',
};

export const NO_LOCATION_ERROR: ErrorDialogConfig = {
  title: 'Something went wrong',
  description: 'Please proceed to the front desk for check-in',
  closeButtonText: 'OK',
};

export const NO_PATIENT_ERROR_ID = 'no-patient';
export const NO_PATIENT_ERROR: ErrorDialogConfig = {
  title: 'Something went wrong',
  description: 'Please select a patient in order to book an appointment',
  closeButtonText: 'OK',
  id: NO_PATIENT_ERROR_ID,
};

export const NO_SLOT_ERROR_ID = 'no-patient';
export const NO_SLOT_ERROR: ErrorDialogConfig = {
  title: 'Something went wrong',
  description: 'Please select a time slot in order to book an appointment',
  closeButtonText: 'OK',
  id: NO_SLOT_ERROR_ID,
};

export const PAST_APPT_ERROR_ID = 'past-appt-date-time';
export const PAST_APPT_ERROR: ErrorDialogConfig = {
  title: `Error booking visit`,
  description:
    "We're sorry, your selected check-in time is in the past. Please click below to select a different time slot.",
  closeButtonText: 'Select time slot',
  id: PAST_APPT_ERROR_ID,
};

export const UNEXPECTED_ERROR_CONFIG: ErrorDialogConfig = {
  title: 'Unexpected error',
  description: UnexpectedErrorDescription,
  closeButtonText: 'Close',
};
