import { useMemo, useRef, useEffect } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { PMQuestionnaireItem, flattenPMQuestionnaireItems } from 'utils';
import { getItemDisplayStrategy } from './useSelectItems';
import { QuestionnaireResponseItem } from 'fhir/r4';

export const useItemsToAutoFill = (
  questionnaireItems: PMQuestionnaireItem[],
  allFields: FieldValues
): PMQuestionnaireItem[] => {
  return useMemo(() => {
    // console.log('all fields', JSON.stringify(allFields));
    const flattenedItems = flattenPMQuestionnaireItems(questionnaireItems);
    return flattenedItems.filter((qi) => {
      if (!allFields) {
        return false;
      }
      // console.log('allFields useitems to auto fill', allFields);
      const displayStrategy = getItemDisplayStrategy(qi, questionnaireItems, allFields);
      if (displayStrategy === 'hidden' || displayStrategy === 'protected') {
        return qi.autofillFromWhenDisabled !== undefined;
      }
      return false;
    });
  }, [allFields, questionnaireItems]);
};

const objectsEqual = (obj1: any, obj2: any): boolean => {
  const objectOneNoLink = { ...obj1, linkId: undefined };
  const objectTwoNoLink = { ...obj2, linkId: undefined };
  return JSON.stringify(objectOneNoLink) === JSON.stringify(objectTwoNoLink);
};

const autoFill = (from: QuestionnaireResponseItem, to: PMQuestionnaireItem): QuestionnaireResponseItem => {
  const { item: fromItem, answer: fromAnswer } = from;
  const { linkId, item: toItem } = to;

  if (fromAnswer) {
    return { linkId, answer: fromAnswer };
  }
  if (!toItem) {
    return { linkId, answer: undefined };
  }
  if (fromItem) {
    if (toItem) {
      return {
        linkId,
        item: fromItem.map((item, index) => {
          return autoFill(item, toItem[index]);
        }),
      };
    } else {
      return { linkId, item: undefined };
    }
  } else {
    return { linkId, item: undefined };
  }
};

const makeEmptyResponseItem = (item: PMQuestionnaireItem): QuestionnaireResponseItem => {
  if (!item.item) {
    return {
      linkId: item.linkId,
    };
  }
  return {
    linkId: item.linkId,
    item: (item.item ?? []).map((i) => makeEmptyResponseItem(i)),
  };
};

export const useAutoFillValues = (
  itemsToFill: PMQuestionnaireItem[],
  setValue: UseFormSetValue<FieldValues>,
  allFields: FieldValues,
  formValues: FieldValues
): void => {
  const replacedValues = useRef<FieldValues>({});
  return useEffect(() => {
    let shouldUpdateValue = false;
    itemsToFill.forEach((item) => {
      const autofillSource = item.autofillFromWhenDisabled; // the name of the field that's the source of the auto fill value
      // console.log('autofillSource', autofillSource, allFields);
      if (!autofillSource) {
        return;
      }
      const autofillValue = allFields[autofillSource ?? ''];
      if (!autofillValue) {
        return;
      }
      // console.log('autofillValue', autofillValue);
      const currentValue = formValues[item.linkId] ?? makeEmptyResponseItem(item);
      const autoFilled = autoFill(autofillValue, item);
      // console.log('autofilledValue', autoFilled);
      shouldUpdateValue = // the comparison bewteen autofillValue and currentValue is necessary to avoid an infinite render loop
        autofillSource && autofillValue && typeof autofillValue === 'object' && !objectsEqual(autoFilled, currentValue);
      if (shouldUpdateValue) {
        replacedValues.current[item.linkId] = currentValue;
        setValue(item.linkId, autoFilled, { shouldValidate: true });
      }
    });
    // replace previously autofilled values
    Object.entries(replacedValues.current).forEach((entry) => {
      const [key, val] = entry;
      if (val === undefined) {
        return;
      }
      if (!itemsToFill.some((item) => item?.linkId === key) && !shouldUpdateValue) {
        const newVal = val || undefined;
        const currentVal = allFields[key];
        // console.log('new val, current val', newVal, currentVal);
        if (!objectsEqual(newVal, currentVal)) {
          // console.log('unsetting a value', val || undefined);
          setValue(key, val || undefined);
          replacedValues.current[key] = undefined;
        }
      }
    });
  }, [allFields, setValue, itemsToFill, formValues]);
};
