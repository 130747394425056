import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { getSlugAndStateFromLocation } from 'utils';
import { IntakeFlowPageRoute } from '../App';
import { UCContainer } from '../components';
import { PhoneNumberMessage, useVisitContext } from './ThankYou';
import { useTrackMixpanelEvents } from '../hooks/useTrackMixpanelEvents';
import { useEffect, useMemo } from 'react';

const CancellationConfirmation = (): JSX.Element => {
  const { appointmentData } = useVisitContext();

  const selectedLocation = useMemo(() => {
    return appointmentData?.appointment?.location;
  }, [appointmentData?.appointment?.location]);
  const visitType = useMemo(() => {
    return appointmentData?.appointment?.visitType;
  }, [appointmentData?.appointment?.visitType]);

  useTrackMixpanelEvents({
    eventName: 'Cancellation Confirmation',
    visitType: visitType,
    bookingCity: selectedLocation?.address?.city,
    bookingState: selectedLocation?.address?.state,
  });

  const { slug, state } = useMemo(() => {
    return getSlugAndStateFromLocation(selectedLocation);
  }, [selectedLocation]);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', handlePopstate);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handlePopstate = (_event: any): void => {
    window.history.pushState(null, document.title, window.location.href);
  };

  const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
    event.preventDefault();
    event.returnValue = '';
  };

  return (
    <UCContainer
      title="Your visit has been canceled"
      description={<PhoneNumberMessage locationTelecom={selectedLocation?.telecom} />}
      bgVariant={IntakeFlowPageRoute.CancellationConfirmation.path}
    >
      {state && slug && visitType && (
        <Link to={`/location/${state}/${slug}/${visitType}`} className="appointment-button">
          <Button variant="outlined" size="large" type="button">
            Book again
          </Button>
        </Link>
      )}
    </UCContainer>
  );
};

export default CancellationConfirmation;
