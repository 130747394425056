import { IntakeFlowPageRoute } from '../App';
import { Typography } from '@mui/material';
import { UCContainer } from '../components';
import { useTrackMixpanelEvents } from '../hooks/useTrackMixpanelEvents';
import ConfirmDateOfBirthForm from '../components/ConfirmDateOfBirthForm';
import { useBookingContext } from './Welcome';
import { useNavigateInFlow } from '../hooks/useNavigateInFlow';

const ConfirmDateOfBirth = (): JSX.Element => {
  const { visitType, selectedLocation, patientInfo, unconfirmedDateOfBirth, setUnconfirmedDateOfBirth } =
    useBookingContext();
  const navigateInFlow = useNavigateInFlow();

  useTrackMixpanelEvents({
    eventName: 'Confirm Date of Birth',
    visitType: visitType,
    bookingCity: selectedLocation?.address?.city,
    bookingState: selectedLocation?.address?.state,
  });

  const handleContinueAnywaySubmit = (): void => {
    navigateInFlow('patient-information');
  };

  return (
    <UCContainer
      title={`Confirm ${patientInfo?.firstName ? `${patientInfo?.firstName}'s` : 'patient’s'} date of birth`}
      bgVariant={IntakeFlowPageRoute.ConfirmDateOfBirth.path}
    >
      <ConfirmDateOfBirthForm
        patientInfo={patientInfo}
        defaultValue={unconfirmedDateOfBirth}
        required={true}
        onConfirmedSubmit={async () => {
          // in case the user initially set the wrong birthday, but then clicked 'back' and fixed it
          setUnconfirmedDateOfBirth(undefined);
          navigateInFlow('patient-information');
        }}
        onUnconfirmedSubmit={(unconfirmedDateOfBirth: string) => {
          setUnconfirmedDateOfBirth(unconfirmedDateOfBirth);
        }}
        wrongDateOfBirthModal={{
          buttonText: 'Continue anyway',
          message: <Typography marginTop={2}>You can try again or continue and verify DOB at check-in.</Typography>,
          onSubmit: handleContinueAnywaySubmit,
        }}
      />
    </UCContainer>
  );
};

export default ConfirmDateOfBirth;
