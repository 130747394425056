import { useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress, Divider, Skeleton, Typography, useTheme, Button } from '@mui/material';
import { useEffect, useState, useMemo, FC } from 'react';
import {
  Link,
  Navigate,
  Outlet,
  generatePath,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { ErrorDialog, ErrorDialogConfig, PageForm, FormInputType } from 'ui-components';
import { GetLocationResponse, VisitType, getSelectors } from 'utils';
import { IntakeFlowPageRoute, bookingBasePath } from '../App';
import zapehrApi, { AvailableLocationInformation } from '../api/zapehrApi';
import { stethoscope } from '../assets/icons';
import { UCContainer, Schedule } from '../components';
import { WaitingEstimateCard } from '../components/WaitingEstimateCard';
import { NO_LOCATION_ERROR, getAuthedLandingPage, NOT_PATIENT_OR_GUARDIAN_ERROR } from '../helpers';
import { useTrackMixpanelEvents } from '../hooks/useTrackMixpanelEvents';
import { ZambdaClient, useUCZambdaClient } from '../hooks/useUCZambdaClient';
import { PatientInfo } from 'utils';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { PatientInfoInProgress } from '../features/patients/types';
import { useCheckOfficeOpen } from '../hooks/useCheckOfficeOpen';
import { useNavigateInFlow } from '../hooks/useNavigateInFlow';
import { usePreserveQueryParams } from '../hooks/usePreserveQueryParams';
import { fillingThisOutAsOptions, FillingOutAsValue } from '../helpers';
import { otherColors } from '../IntakeThemeProvider';

type BookingState = {
  visitType: VisitType | undefined;
  selectedLocationResponse: GetLocationResponse | undefined;
  selectedSlot: string | undefined;
  patients: PatientInfo[];
  patientInfo: PatientInfoInProgress | undefined;
  unconfirmedDateOfBirth: string | undefined;
  locationPath: string | undefined;
  formUser: FillingOutAsValue | undefined;
};

interface BookingStoreActions {
  setPatientInfo: (info: PatientInfoInProgress | undefined) => void;
  setPatients: (patients: PatientInfo[]) => void;
  setUnconfirmedDateOfBirth: (dob: string | undefined) => void;
  setSelectedLocationResponse: (location: GetLocationResponse | undefined) => void;
  setSelectedSlot: (slot: string | undefined) => void;
  setSlotData: (slotData: string[]) => void;
  completeBooking: () => void;
  restartBooking: (newLocationPath: string) => void;
  handleLogout: () => void;
  setFormUser: (formUser: FillingOutAsValue) => void;
}

const BOOKING_INITIAL: BookingState = {
  patients: [],
  patientInfo: undefined,
  unconfirmedDateOfBirth: undefined,
  selectedLocationResponse: undefined,
  selectedSlot: undefined,
  locationPath: undefined,
  visitType: undefined,
  formUser: undefined,
};

const useBookingStore = create<BookingState & BookingStoreActions>()(
  persist(
    (set) => ({
      ...BOOKING_INITIAL,
      clear: () => {
        set({
          ...BOOKING_INITIAL,
        });
      },
      setPatientInfo: (info: PatientInfoInProgress | undefined) => {
        set((state) => {
          let isNewPatientInfo = false;
          if (state.patientInfo && state.patientInfo.id !== info?.id) {
            isNewPatientInfo = true;
          }
          return {
            ...state,
            patientInfo: info,
            unconfirmedDateOfBirth: isNewPatientInfo ? undefined : state.unconfirmedDateOfBirth,
          };
        });
      },
      setPatients: (patients: PatientInfo[]) => {
        set((state) => ({
          ...state,
          patients,
        }));
      },
      setUnconfirmedDateOfBirth: (unconfirmedDateOfBirth: string | undefined) => {
        set((state) => ({
          ...state,
          unconfirmedDateOfBirth,
        }));
      },
      setVisitType: (visitType: VisitType | undefined) => {
        set((state) => ({
          ...state,
          visitType,
        }));
      },
      setSelectedLocationResponse: (selectedLocationResponse: GetLocationResponse | undefined) => {
        set((state) => ({
          ...state,
          selectedLocationResponse,
        }));
      },
      setSlotData: (slotData: string[]) => {
        set((state) => {
          const selectedLocationResponse = state.selectedLocationResponse;
          if (!selectedLocationResponse) {
            return { ...state };
          }
          return {
            ...state,
            selectedLocationResponse: {
              ...selectedLocationResponse,
              available: slotData,
            },
          };
        });
      },
      setSelectedSlot: (selectedSlot: string | undefined) => {
        set((state) => ({
          ...state,
          selectedSlot,
        }));
      },
      completeBooking: () => {
        set((state) => ({
          ...state,
          selectedSlot: undefined,
          patientInfo: undefined,
          unconfirmedDateOfBirth: undefined,
          formUser: undefined,
        }));
      },
      restartBooking: (newLocationPath: string) => {
        const split = newLocationPath.split('/');
        const isWalkin = split[split.length - 1] === 'walkin';
        set((state) => ({
          ...state,
          patientInfo: isWalkin ? undefined : state.patientInfo,
          locationPath: newLocationPath,
          selectedSlot: undefined,
          selectedLocationResponse: undefined,
          unconfirmedDateOfBirth: undefined,
        }));
      },
      handleLogout: () => {
        set(() => ({
          ...BOOKING_INITIAL,
        }));
      },
      setFormUser: (formUser: FillingOutAsValue) => {
        set((state) => ({
          ...state,
          formUser,
        }));
      },
    }),
    { name: 'uc-intake-booking-store' }
  )
);

enum LoadingState {
  initial,
  loading,
  complete,
}

interface BookAppointmentContext
  extends Omit<BookingState, 'locationPath' | 'selectedLocationResponse' | 'redirectToStart'>,
    Omit<
      BookingStoreActions,
      'restartBooking' | 'setLocationPath' | 'setSelectedLocationResponse' | 'handleLogout' | 'setPatients'
    > {
  visitType: VisitType | undefined;
  selectedLocation: AvailableLocationInformation | undefined;
  slotData: string[];
  waitingMinutes: number | undefined;
  locationLoading: boolean;
  patientsLoading: boolean;
}

export const useBookingContext = (): BookAppointmentContext => {
  const outletContext = useOutletContext<BookAppointmentContext>();
  return {
    ...outletContext,
  };
};

interface CustomContainerText {
  title: string;
  subtext?: string;
}

const isPostPatientSelectionPath = (basePath: string, pathToCheck: string): boolean => {
  // review is last step but we detect on submit instead so redirect doesnt jump
  // the transition to the appointment page
  const prepatientSelectionPaths = [basePath, `${basePath}/get-ready`, `${basePath}/patients`, `${basePath}/review`];
  const normalized = pathToCheck.split('?')[0];
  return !prepatientSelectionPaths.includes(normalized);
};

const BookingHome: FC = () => {
  const {
    locationPath: persistedLocationPath,
    selectedLocationResponse,
    patients,
    patientInfo,
    selectedSlot,
    unconfirmedDateOfBirth,
    formUser,
    restartBooking,
    setSelectedLocationResponse,
    setPatientInfo,
    setPatients,
    setUnconfirmedDateOfBirth,
    setSelectedSlot,
    completeBooking,
    setSlotData,
    handleLogout,
    setFormUser,
  } = getSelectors(useBookingStore, [
    'restartBooking',
    'locationPath',
    'patientInfo',
    'unconfirmedDateOfBirth',
    'formUser',
    'selectedLocationResponse',
    'setSelectedLocationResponse',
    'patients',
    'visitType',
    'selectedSlot',
    'setPatientInfo',
    'setPatients',
    'setUnconfirmedDateOfBirth',
    'setSelectedSlot',
    'completeBooking',
    'setSlotData',
    'handleLogout',
    'setFormUser',
  ]);
  const { slug: slugParam, visit_type: visitTypeParam, state: stateParam } = useParams();
  const [locationLoading, setLocationLoading] = useState(LoadingState.initial);
  const [patientsLoading, setPatientsLoading] = useState(LoadingState.initial);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const tokenlessZambdaClient = useUCZambdaClient({ tokenless: true });
  const tokenfulZambdaClient = useUCZambdaClient({ tokenless: false });
  const [pageNotFound, setPageNotFound] = useState(false);
  const [errorConfig, setErrorConfig] = useState<ErrorDialogConfig | undefined>(undefined);
  const { isAuthenticated, isLoading: authIsLoading } = useAuth0();
  const navigateInFlow = useNavigateInFlow();

  const outletContext: BookAppointmentContext = useMemo(() => {
    let slotDataTemp: string[] = [];
    let selectedLocationTemp: AvailableLocationInformation | undefined = undefined;
    let waitingMinutesTemp: number | undefined = undefined;
    if (selectedLocationResponse) {
      selectedLocationTemp = selectedLocationResponse.location;
      slotDataTemp = selectedLocationResponse.available;
      waitingMinutesTemp = selectedLocationResponse.waitingMinutes;
    }
    let visitType = VisitType.PreBook;
    if (visitTypeParam === VisitType.WalkIn) {
      visitType = VisitType.WalkIn;
    }
    return {
      patients,
      patientInfo,
      slotData: slotDataTemp,
      selectedLocation: selectedLocationTemp,
      waitingMinutes: waitingMinutesTemp,
      visitType,
      locationLoading: locationLoading !== LoadingState.complete,
      patientsLoading: patientsLoading !== LoadingState.complete,
      selectedSlot,
      unconfirmedDateOfBirth,
      formUser,
      restartBooking,
      setPatientInfo,
      setUnconfirmedDateOfBirth,
      setSelectedSlot,
      completeBooking,
      setSlotData,
      setFormUser,
    };
  }, [
    selectedLocationResponse,
    visitTypeParam,
    patients,
    patientInfo,
    locationLoading,
    patientsLoading,
    selectedSlot,
    unconfirmedDateOfBirth,
    formUser,
    restartBooking,
    setPatientInfo,
    setUnconfirmedDateOfBirth,
    setSelectedSlot,
    completeBooking,
    setSlotData,
    setFormUser,
  ]);

  useEffect(() => {
    if (!isAuthenticated && !authIsLoading) {
      handleLogout();
    }
  }, [authIsLoading, handleLogout, isAuthenticated]);

  // console.log('outlet context in root', outletContext);
  console.log('patientsLoading', patientsLoading);

  useEffect(() => {
    const fetchLocation = async (locationSlug: string, locationState: string): Promise<any> => {
      try {
        if (!tokenlessZambdaClient) {
          return;
        }
        setLocationLoading(LoadingState.loading);
        const res = await zapehrApi.getLocation(tokenlessZambdaClient, {
          locationSlug,
          locationState,
        });
        setSelectedLocationResponse(res);
      } catch (error) {
        setPageNotFound(true);
        console.error('Error validating location: ', error);
      } finally {
        setLocationLoading(LoadingState.complete);
      }
    };

    // So long as / is a valid path or auth0 redirects to /, this must be here. Otherwise the
    // function is called with no slug parameter and overwrites the contents of local storage.
    if (slugParam && stateParam && locationLoading === LoadingState.initial) {
      void fetchLocation(slugParam, stateParam);
    }
  }, [locationLoading, setSelectedLocationResponse, slugParam, stateParam, tokenlessZambdaClient]);

  useEffect(() => {
    async function getPatients(): Promise<void> {
      if (!tokenfulZambdaClient) {
        return;
      }
      setPatientsLoading(LoadingState.loading);

      const response = await zapehrApi.getPatients(tokenfulZambdaClient);
      const patients = response.patients;

      if (patients.length > 0) {
        setPatients(patients);
      } else {
        // Navigate to NewUser if patients not found
        navigateInFlow('new-user');
      }
    }

    if (isAuthenticated && slugParam && stateParam && patientsLoading === LoadingState.initial) {
      getPatients()
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setPatientsLoading(LoadingState.complete));
    }
  }, [isAuthenticated, setPatients, slugParam, stateParam, tokenfulZambdaClient, patientsLoading, navigateInFlow]);

  useEffect(() => {
    const visitType = visitTypeParam ?? 'prebook';
    // console.log('effect rendered');
    if (slugParam && stateParam) {
      const newLocationPath = `/location/${stateParam}/${slugParam}/${visitType}`;
      if (!persistedLocationPath) {
        // console.log('effect path 1.1');
        restartBooking(newLocationPath);
        setLocationLoading(LoadingState.initial);
      } else if (persistedLocationPath !== newLocationPath) {
        // console.log('effect path 1.2');
        restartBooking(newLocationPath);
        setLocationLoading(LoadingState.initial);
        navigate(newLocationPath);
      }
    }
  }, [navigate, persistedLocationPath, restartBooking, slugParam, stateParam, visitTypeParam]);

  useEffect(() => {
    const recoverFromLostData = async (slug: string, state: string, zambdaClient: ZambdaClient): Promise<void> => {
      try {
        const res = await zapehrApi.getLocation(zambdaClient, {
          locationSlug: slug,
          locationState: state,
        });
        setSelectedLocationResponse(res);
      } catch (e) {
        console.error('selected location unexpectedly missing from store -- could not recover');
        // show an error asking user to go to front desk
        setErrorConfig(NO_LOCATION_ERROR);
        return;
      }
    };
    if (
      !outletContext.selectedLocation &&
      locationLoading === LoadingState.complete &&
      slugParam &&
      stateParam &&
      tokenlessZambdaClient
    ) {
      console.error('selected location unexpectedly missing from store -- attempting recovery');
      void recoverFromLostData(slugParam, stateParam, tokenlessZambdaClient);
    }
  }, [
    locationLoading,
    outletContext.selectedLocation,
    setSelectedLocationResponse,
    slugParam,
    stateParam,
    tokenlessZambdaClient,
  ]);

  const renderWelcome = useMemo(() => {
    if (pathname === '/') {
      return true;
    }
    console.log('pathname', pathname);
    // '/location/:state/:slug/:visit_type'
    if (stateParam && slugParam && visitTypeParam) {
      const solvedPath = generatePath(bookingBasePath, {
        state: stateParam,
        slug: slugParam,
        visit_type: visitTypeParam,
      });
      if (solvedPath === pathname) {
        return true;
      } else {
        return false;
      }
    }
    return false; // figure out what to do here
  }, [pathname, slugParam, stateParam, visitTypeParam]);

  // all this is to say, "if the user wound up somewhere in
  // the booking flow by finishing the booking and then pounding
  // the back button, escort them gently to the start of the flow"
  if (!patientInfo) {
    if (stateParam && slugParam && visitTypeParam) {
      const solvedPath = generatePath(pathname, {
        state: stateParam,
        slug: slugParam,
        visit_type: visitTypeParam ?? 'prebook',
      });
      const basePath = generatePath(bookingBasePath, {
        state: stateParam,
        slug: slugParam,
        visit_type: visitTypeParam ?? 'prebook',
      });
      const shouldStartAtBeginning = isPostPatientSelectionPath(basePath, solvedPath) && !patientsLoading;
      // console.log('basePath, solvedPath, shouldSAB', basePath, solvedPath, shouldStartAtBeginning);
      if (shouldStartAtBeginning) {
        return <Navigate to={basePath} replace={true} />;
      }
    }
  }

  if (pageNotFound) {
    return (
      <UCContainer title="Not Found" bgVariant={IntakeFlowPageRoute.Welcome.path}>
        <Typography variant="body1">
          You have navigated to a page that is not found. To find a PM Pediatric Care location,{' '}
          <a href="https://pmpediatriccare.com/find-care/">please visit our website</a>.
        </Typography>
      </UCContainer>
    );
  }

  // console.log('outlet context', outletContext);
  console.log('render welcome', renderWelcome);
  return (
    <>
      {renderWelcome ? <Welcome context={outletContext} /> : <Outlet context={{ ...outletContext }} />}
      <ErrorDialog
        open={errorConfig != undefined}
        title={errorConfig?.title ?? ''}
        description={errorConfig?.description ?? ''}
        closeButtonText={errorConfig?.closeButtonText ?? 'OK'}
        handleClose={() => {
          setErrorConfig(undefined);
        }}
      />
    </>
  );
};

const Welcome: FC<{ context: BookAppointmentContext }> = ({ context }) => {
  const navigate = useNavigate();
  const { slug: slugParam, visit_type: visitTypeParam, state: stateParam } = useParams();
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [errorConfig, setErrorConfig] = useState<ErrorDialogConfig | undefined>(undefined);
  const theme = useTheme();
  const preserveQueryParams = usePreserveQueryParams();

  console.log('isAuthenticated in welcome page', isAuthenticated);

  const {
    visitType,
    selectedLocation,
    selectedSlot,
    waitingMinutes,
    slotData,
    locationLoading,
    formUser,
    setSelectedSlot,
    setSlotData,
    setFormUser,
  } = context;

  // console.log('selectedLocation, locationLoading', selectedLocation, locationLoading, context);

  // Track Welcome page in Mixpanel
  useTrackMixpanelEvents({
    eventName: 'Welcome',
    visitType: visitTypeParam as VisitType,
    // Prevent tracking the last used visit type when switching visit types
    loading: visitType !== visitTypeParam || !selectedLocation || isLoading,
    bookingCity: selectedLocation?.address?.city,
    bookingState: selectedLocation?.address?.state,
  });

  const { officeOpen, walkinOpen, officeHasClosureOverrideToday, officeHasClosureOverrideTomorrow } =
    useCheckOfficeOpen(selectedLocation);

  const allAvailableSlots = useMemo(() => {
    if (!selectedSlot) {
      return slotData;
    } else {
      const allButSelected =
        slotData?.filter((slot) => {
          return slot !== selectedSlot;
        }) ?? [];
      return [...allButSelected, selectedSlot].sort((a: string, b: string) => a.localeCompare(b));
    }
  }, [slotData, selectedSlot]);

  const getCustomContainerText = (): CustomContainerText => {
    if (visitTypeParam === VisitType.PreBook) {
      return { title: 'Select check-in time' };
    } else if (visitTypeParam === VisitType.WalkIn && !walkinOpen && !locationLoading) {
      return { title: 'This PM Pediatric office is closed now' };
    } else {
      return { title: 'Welcome to PM Pediatric Urgent Care', subtext: 'We look forward to helping you soon!' };
    }
  };

  const { title, subtext } = getCustomContainerText();

  const otherOffices = (
    <>
      {locationLoading ? (
        <Skeleton width={200} style={{ display: 'inline-block' }} />
      ) : selectedLocation?.otherOffices.length === 0 ? (
        <span style={{ display: 'inline' }}>
          <Typography variant="body2" color={theme.palette.text.primary} marginTop={1}>
            Try connecting with a pediatric expert
            <Link
              style={{ color: theme.palette.primary.main, marginLeft: 5 }}
              target="_blank"
              to={'https://pmpediatriccare.com/find-care/virtual-visit/'}
            >
              virtually
            </Link>
            .
          </Typography>
        </span>
      ) : (
        <Typography variant="body2" color={theme.palette.text.primary} marginTop={1}>
          Check out one of our other offices:{' '}
          {selectedLocation?.otherOffices.map((otherLocation, idx) => {
            return (
              <span key={otherLocation.url}>
                <span style={{ display: 'inline' }}>
                  <Link style={{ color: theme.palette.primary.main }} target="_blank" to={otherLocation.url}>
                    {otherLocation.display}
                  </Link>
                  {idx < selectedLocation.otherOffices.length - 1 ? ', ' : '.'}
                </span>
              </span>
            );
          })}
          <Typography variant="body2" color={theme.palette.text.primary} marginTop={1}>
            Or connect{' '}
            <Link
              style={{ color: theme.palette.primary.main }}
              target="_blank"
              to={'https://pmpediatriccare.com/find-care/virtual-visit/'}
            >
              virtually
            </Link>
            .
          </Typography>
        </Typography>
      )}
    </>
  );

  const formElements: FormInputType[] = useMemo(
    () => [
      {
        type: 'Radio',
        name: 'formUser',
        label: 'Are you filling out this information as:',
        required: true,
        defaultValue: formUser,
        radioOptions: fillingThisOutAsOptions,
        borderColor: otherColors.borderGray,
        backgroundSelected: otherColors.lightPurpleAlt,
        radioStyling: {
          radio: {
            alignSelf: 'center',
            marginY: 'auto',
          },
          label: {
            ...theme.typography.body2,
            color: theme.palette.text.primary,
          },
        },
      },
    ],
    [theme.palette.text.primary, theme.typography.body2, formUser]
  );

  // console.log('selected slot', selectedSlot);

  const bgVariant =
    visitTypeParam === VisitType.WalkIn ? IntakeFlowPageRoute.Welcome.path : IntakeFlowPageRoute.WelcomeType.path;

  return (
    <UCContainer
      title={title}
      subtitle={locationLoading ? 'Loading...' : `${selectedLocation?.name}`}
      subtext={locationLoading ? '' : subtext}
      isFirstPage
      img={stethoscope}
      imgAlt="Stethoscope icon"
      imgWidth={150}
      bgVariant={bgVariant}
      topOutsideCardComponent={
        visitTypeParam === VisitType.PreBook && officeOpen ? (
          <WaitingEstimateCard waitingMinutes={waitingMinutes} />
        ) : undefined
      }
    >
      {visitTypeParam === VisitType.PreBook && (
        <>
          {officeOpen && (
            <Box>
              <Typography variant="body1">We look forward to helping you very soon!</Typography>
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                Don't see an option you want? Come right in! Walk-ins are welcome, and we always prioritize severe
                injuries and illness.
              </Typography>
            </Box>
          )}

          <Schedule
            slotsLoading={locationLoading}
            slotData={allAvailableSlots}
            setSlotData={setSlotData}
            timezone={selectedLocation?.timezone || 'America/New_York'}
            existingSelectedSlot={selectedSlot}
            handleSlotSelected={(slot) => {
              setSelectedSlot(slot);
              navigate(preserveQueryParams(`/location/${stateParam}/${slugParam}/${visitTypeParam}/get-ready`), {
                state: { waitingTime: waitingMinutes?.toString() },
              });
            }}
            locationSlug={slugParam}
            locationState={stateParam}
            forceClosedToday={officeHasClosureOverrideToday}
            forceClosedTomorrow={officeHasClosureOverrideTomorrow}
            markSlotBusy={true}
          />
          <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
          <Typography variant="h4" color={theme.palette.primary.main}>
            Not seeing a time you need?
          </Typography>
          {otherOffices}
        </>
      )}
      {visitTypeParam === VisitType.WalkIn &&
        (!locationLoading ? (
          walkinOpen ? (
            <>
              <Typography variant="body1" marginTop={2}>
                Please click on Continue to proceed to a page where you will enter your phone number. We'll verify if we
                have your information already. If we do, we will pre-fill your past information for a faster booking. If
                you already have a check-in time booked, please select Check-in option after login.
              </Typography>
              <Typography variant="body1" marginTop={1} marginBottom={2}>
                Please note that your family's information may be registered under a phone number owned by your partner,
                spouse, or child's guardian.
              </Typography>
              <PageForm
                formElements={formElements}
                onSubmit={(data) => {
                  const formUserSelected = data?.formUser as FillingOutAsValue;
                  setFormUser(formUserSelected);
                  if (formUserSelected === FillingOutAsValue.Other) {
                    setErrorConfig(NOT_PATIENT_OR_GUARDIAN_ERROR);
                  } else if (!isAuthenticated) {
                    // if the user is not signed in, redirect them to auth0
                    loginWithRedirect({
                      appState: {
                        target: preserveQueryParams(`/location/${stateParam}/${slugParam}/${visitTypeParam}/patients`),
                      },
                    }).catch((error) => {
                      throw new Error(`Error calling loginWithRedirect Auth0: ${error}`);
                    });
                  } else {
                    // if the location has loaded and the user is signed in, redirect them to the landing page
                    navigate(getAuthedLandingPage(stateParam || '', slugParam || '', visitTypeParam));
                  }
                }}
                controlButtons={{ backButton: false }}
              ></PageForm>
              <ErrorDialog
                open={errorConfig != undefined}
                title={errorConfig?.title ?? ''}
                description={errorConfig?.description ?? ''}
                closeButtonText={errorConfig?.closeButtonText ?? 'OK'}
                handleClose={() => {
                  setErrorConfig(undefined);
                }}
              />
            </>
          ) : (
            <>
              <Typography variant="body1" marginTop={1}>
                Sorry! We are closed at the moment. Please visit our website to see open offices.
              </Typography>
              <Typography variant="h4" color={theme.palette.primary.main} marginTop={3.5}>
                Consider visiting another office or a telemedicine visit
              </Typography>
              {otherOffices}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2.5 }}>
                <Link
                  to="https://pmpediatriccare.com/find-care/"
                  aria-label="PM Pediatric Care website"
                  target="_blank"
                >
                  <Button variant="contained" color="primary">
                    Go to PM website
                  </Button>
                </Link>
              </Box>
            </>
          )
        ) : (
          <CircularProgress />
        ))}
    </UCContainer>
  );
};

export default BookingHome;
