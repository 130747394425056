import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { isoStringFromDateComponents, yupDateTransform } from 'utils';
import { otherColors } from '../../../IntakeThemeProvider';
import { PatientInfoInProgress } from '../types';
import { useGetFullName } from '../../../hooks/useGetFullName';

export const PatientInformationKnownPatientFieldsDisplay = ({
  patientInfo,
  unconfirmedDateOfBirth,
  selectPatientPageUrl,
}: {
  patientInfo: PatientInfoInProgress;
  unconfirmedDateOfBirth?: string;
  selectPatientPageUrl: string;
}): JSX.Element => {
  const patientFullName = useGetFullName(patientInfo);
  const formattedBirthday = DateTime.fromFormat(
    yupDateTransform(
      unconfirmedDateOfBirth ??
        isoStringFromDateComponents({
          day: patientInfo?.dobDay ?? '',
          month: patientInfo?.dobMonth ?? '',
          year: patientInfo?.dobYear ?? '',
        })
    ),
    'yyyy-MM-dd'
  ).toFormat('MMMM dd, yyyy');
  return (
    <>
      <Typography variant="h3" color="secondary.main">
        {patientFullName}
      </Typography>
      <Typography variant="body2" sx={{ fontSize: '14px' }} color="secondary.main">
        Birthday: {formattedBirthday}
      </Typography>
      <Typography variant="body1" color={otherColors.wrongPatient} marginTop={2} marginBottom={4}>
        Wrong patient? Please{' '}
        <Link style={{ color: otherColors.wrongPatient }} to={selectPatientPageUrl}>
          go back
        </Link>{' '}
        for a new patient or different existing patient record.
      </Typography>
    </>
  );
};
