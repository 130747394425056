import {
  QuestionnaireItem,
  QuestionnaireItemEnableWhen,
  QuestionnaireResponseItem,
  QuestionnaireResponseItemAnswer,
} from 'fhir/r4';
import { DateTime } from 'luxon';
import {
  DATE_ERROR_MESSAGE,
  PMQuestionnaireItem,
  emailRegex,
  emojiRegex,
  getComponentKeysFromDateGroup,
  phoneRegex,
  pickFirstValueFromAnswerItem,
  yupDateTransform,
  yupSimpleDateRegex,
  zipRegex,
} from 'utils';
import * as Yup from 'yup';

interface ValidatableQuestionnaireItem extends PMQuestionnaireItem {
  regex?: RegExp;
  regexError?: string;
  dateComponents?: { day: string; year: string; month: string };
}

// all this logic could be in an extension, but not sure it's worth the trouble
export const PHONE_NUMBER_FIELDS = [
  'patient-number',
  'guardian-number',
  'responsible-party-number',
  'pharmacy-phone',
  'pcp-number',
];
export const EMAIL_FIELDS = ['patient-email', 'guardian-email'];
export const ZIP_CODE_FIELDS = ['patient-zip'];
export const SIGNATURE_FIELDS = ['signature'];
export const FULL_ADDRESS_FIELDS = ['pharmacy-address'];

const makeValidatableItem = (
  item: PMQuestionnaireItem
): ValidatableQuestionnaireItem[] | ValidatableQuestionnaireItem => {
  // todo: add validation for date components
  let regex: RegExp | undefined;
  let regexError: string | undefined;

  // keeping these field checks for backwards compatibility for now
  // can just check item.dataType after 1.14 release
  if (PHONE_NUMBER_FIELDS.includes(item.linkId) || item.dataType === 'Phone Number') {
    regex = phoneRegex;
    regexError = 'Phone number must be 10 digits in the format (xxx) xxx-xxxx';
  }
  if (EMAIL_FIELDS.includes(item.linkId) || item.dataType === 'Email') {
    regex = emailRegex;
    regexError = 'Email is not valid';
  }
  if (ZIP_CODE_FIELDS.includes(item.linkId) || item.dataType === 'ZIP') {
    regex = zipRegex;
    regexError = 'ZIP Code must be 5 numbers';
  }
  return {
    ...item,
    regex,
    regexError,
  };
};

const wrapSchemaInSingleMemberArray = (schema: Yup.AnyObjectSchema, item: PMQuestionnaireItem): Yup.AnySchema => {
  if (item.required) {
    return Yup.object({
      linkId: Yup.string(),
      answer: Yup.array().of(schema).length(1).required('This field is required'),
    }).required('This field is required');
  }
  return Yup.object({
    linkId: Yup.string().optional(),
    answer: Yup.array().of(schema).max(1).optional(),
  })
    .transform((val: any) => {
      const answer: any = val.answer;
      if (answer == undefined) {
        return undefined;
      }
      if (answer?.[0] == undefined) {
        return undefined;
      } else {
        const obj = answer[0];
        if (typeof obj === 'object') {
          if (Object.keys(obj).length === 0) {
            return null;
          } else if (!Object.values(obj)?.[0]) {
            return undefined;
          }
        }
      }
      return val;
    })
    .optional();
};

const schemaForItem = (item: ValidatableQuestionnaireItem): Yup.AnySchema => {
  let schemaTemp: any | undefined = undefined;
  if (item.type === 'text' || item.type === 'string' || item.type === 'open-choice') {
    let stringSchema = Yup.string().trim().matches(emojiRegex, {
      message: 'Emojis are not a valid character',
      excludeEmptyString: true,
    });
    if (item.regex) {
      stringSchema = stringSchema.matches(item.regex, {
        message: item.regexError,
        excludeEmptyString: true,
      });
    }

    if (item.required) {
      stringSchema = stringSchema.required('This field is required');
    }
    let schema: Yup.AnySchema = Yup.object({
      valueString: stringSchema,
    });
    if (item.required) {
      schema = schema.required('This field is required');
    } else {
      schema = schema.optional();
    }
    schemaTemp = schema;
  }
  if (item.type === 'boolean') {
    let booleanSchema = Yup.boolean();
    if (item.required) {
      booleanSchema = booleanSchema.is([true], 'This field is required').required('This field is required');
    }
    schemaTemp = Yup.object({
      valueBoolean: booleanSchema,
    });
    if (item.required) {
      schemaTemp = schemaTemp.required('This field is required');
    }
  }

  if (item.type === 'choice' && item.answerOption && item.answerOption.length) {
    let stringSchema = Yup.string();
    if (item.required) {
      stringSchema = stringSchema.required('This field is required');
    }
    stringSchema = stringSchema.oneOf(item.answerOption.map((option) => option.valueString));
    let schema = Yup.object({
      valueString: stringSchema,
    });
    if (item.required) {
      schema = schema.required('This field is required');
    }
    schemaTemp = schema;
  }
  if (item.type === 'date') {
    let stringSchema = Yup.string()
      .transform(yupDateTransform)
      .typeError(DATE_ERROR_MESSAGE)
      .matches(yupSimpleDateRegex, DATE_ERROR_MESSAGE);

    if (item.required) {
      stringSchema = stringSchema.required(DATE_ERROR_MESSAGE);
    }
    let schema: Yup.AnySchema = Yup.object({
      valueDate: stringSchema,
    });
    if (item.required) {
      schema = schema.required(DATE_ERROR_MESSAGE);
    } else {
      schema = schema.optional().default(undefined);
    }
    schemaTemp = schema;
  }
  if (item.type === 'attachment') {
    let objSchema: any;
    if (item.required) {
      objSchema = Yup.object({
        valueAttachment: Yup.object({
          url: Yup.string().required('This field is required'), // we could have stronger validation for a z3 url here
          contentType: Yup.string().required('This field is required'),
          title: Yup.string().required('This field is required'),
          created: Yup.string().optional(),
          extension: Yup.array()
            .of(Yup.object({ url: Yup.string(), valueString: Yup.string() }))
            .optional(),
        }).required('This field is required'),
      }).required('This field is required');
    } else {
      objSchema = Yup.object({
        valueAttachment: Yup.object({
          url: Yup.string().required('This field is required'), // we could have stronger validation for a z3 url here
          contentType: Yup.string().required('This field is required'),
          title: Yup.string().required('This field is required'),
          created: Yup.string().optional(),
          extension: Yup.array()
            .of(Yup.object({ url: Yup.string(), valueString: Yup.string() }))
            .optional(),
        }).nullable(),
      })
        .nullable()
        .default(undefined);
    }
    schemaTemp = objSchema;
  }
  if (!schemaTemp) {
    throw new Error(`no schema defined for item ${item.linkId} ${JSON.stringify(item)}`);
  }
  return wrapSchemaInSingleMemberArray(schemaTemp, item);
};

export const makeValidationSchema = (
  items: PMQuestionnaireItem[],
  pageId?: string,
  externalContext?: { values: any; items: any }
): Yup.AnySchema | Yup.AnyObjectSchema => {
  // console.log('validation items', items);
  if (pageId !== undefined) {
    // we are validating one page of the questionnaire
    const itemsToValidate = items.find((i) => {
      return i.linkId === pageId;
    })?.item;
    if (itemsToValidate !== undefined) {
      return makeValidationSchemaPrivate(itemsToValidate, externalContext);
    } else {
      // this is the branch hit from frontend validation. it is nearly the same as the branch hit by
      // patch. in this case item list is provided directly, where as with Patch it is provided as
      // the item field on { linkId: pageId, item: items }. might be nice to consolidate this.
      console.log('page id not found; assuming it is root and making schema from items');
      return makeValidationSchemaPrivate(items, externalContext);
    }
  } else {
    // we are validating the entire questionnaire
    return Yup.array().of(
      Yup.object().test('submit test', (value: any, context: any) => {
        const { linkId: pageId, item: answerItem } = value;
        const questionItem = items.find((i) => i.linkId === pageId);
        if (!questionItem) {
          console.log('page not found');
          return context.createError({ message: `Page ${pageId} not found in Questionnaire` });
        }
        if (answerItem === undefined) {
          if (questionItem.item?.some((i) => evalRequired(i, context))) {
            return context.createError({ message: 'Item not found' });
          } else {
            return value;
          }
        }
        const schema = makeValidationSchemaPrivate(questionItem.item ?? [], context);
        // we convert this from a list to key-val dict to match the form shape
        try {
          const reduced = answerItem.reduce((accum: any, current: any) => {
            accum[current.linkId] = { ...current };
            return accum;
          }, {});
          return schema.validate(reduced, { abortEarly: false });
        } catch (e) {
          console.log('error: ', pageId, JSON.stringify(answerItem), e);
          return e;
        }
      })
    );
  }
};

const makeValidationSchemaPrivate = (
  items: PMQuestionnaireItem[],
  externalContext?: { values: any; items: any }
): Yup.AnyObjectSchema => {
  // console.log('validation items', items);
  // these allow us some flexibility to inject field dependencies from another
  // paperwork page, or anywhere outside the context of the immediate form being validated
  const externalValues = externalContext?.values ?? {};

  const validatableItems = items
    .filter((item) => item?.type !== 'display' && !item?.readOnly)
    .flatMap((item) => makeValidatableItem(item));
  // console.log('validatable items', validatableItems);
  const validationTemp: any = {};
  validatableItems.forEach((item) => {
    let schemaTemp: any | undefined = item.type !== 'group' ? schemaForItem(item) : undefined;
    // this needs to be done after enableWhen eval
    if (!item.required && item.requireWhen !== undefined && item.type !== 'group') {
      const { question } = item.requireWhen;
      const schemaCopy = schemaTemp ? schemaTemp.clone() : Yup.object().nullable();
      schemaTemp = schemaCopy.when(question, {
        is: (val: any, context: any) => {
          const combinedContext = { ...(externalValues ?? {}), ...(context ?? {}) };
          return evalRequired(item, combinedContext, val);
        },
        then: (_schema: Yup.AnySchema) => schemaForItem({ ...item, required: true }).required('This field is required'),
        otherwise: (schema: Yup.AnySchema) => schema,
      });
    }

    if (schemaTemp !== undefined || item.type === 'group') {
      if (item.type === 'group') {
        validationTemp[item.linkId] = (
          schemaTemp ??
          Yup.object({
            linkId: Yup.string(),
            item: Yup.array().of(
              Yup.object({
                linkId: Yup.string(),
                answer: Yup.array().of(Yup.object({ valueString: Yup.string() })),
              })
            ),
          })
        ).test('DOB test', (value: any, context: any, schema: Yup.AnySchema) => {
          // todo: add dataType === 'group' check
          if (item.item && item.item.length === 3) {
            try {
              const { dayKey, monthKey, yearKey } = getComponentKeysFromDateGroup(item);
              // console.log('dayKey, monthKey, yearKey', dayKey, monthKey, yearKey);
              // console.log('value', JSON.stringify(value), JSON.stringify(context));
              const month = (value?.item ?? []).find((i: any) => i.linkId === monthKey)?.answer?.[0]?.valueString;
              const day = (value?.item ?? []).find((i: any) => i.linkId === dayKey)?.answer?.[0]?.valueString;
              const year = (value?.item ?? []).find((i: any) => i.linkId === yearKey)?.answer?.[0]?.valueString;
              console.log('day, month, year', day, month, year);
              if (!month || !day || !year) {
                const required = evalRequired(item, context.parent);
                if (!required) {
                  return value;
                }
                throw new Error('missing stuff');
              }
              const dt = DateTime.fromObject({
                month,
                day,
                year,
              });
              if (!dt || !dt.isValid) {
                return context.createError({ message: 'Please enter a valid date' });
              }
              // as of now our only date fields are DOBs so this assumption works. in the future we may need to further distinguish
              // between dates in general and dates of birth in particular
              const now = DateTime.now();
              if (dt > now) {
                return context.createError({ message: 'Date may not be in the future' });
              }
              return value;
            } catch (e) {
              console.log('context', context, e);
              return context.createError({ message: 'Please enter a valid date' });
            }
          }
          return schema;
        });
      } else {
        validationTemp[item.linkId] = schemaTemp;
      }
    } else {
      console.log('undefined schema', item.linkId);
    }
  });
  return Yup.object().shape(validationTemp);
};

export const itemAnswerHasValue = (answerItem: QuestionnaireResponseItemAnswer): boolean => {
  const entries = Object.entries(answerItem);
  if (entries.length === 0) {
    return false;
  }

  return entries.some((entry) => {
    const [_, val] = entry;
    return val !== undefined;
  });
};

type EnableWhenOperator = 'exists' | '=' | '!=' | '>' | '<' | '>=' | '<=';

const evalBoolean = (operator: EnableWhenOperator, answerValue: boolean, value: boolean | undefined): boolean => {
  if (operator === 'exists') {
    return value !== undefined;
  }

  if (operator === '=') {
    return answerValue == value;
  } else if (operator === '!=') {
    return answerValue != value;
  }
  throw new Error(`Unexpected operator ${operator} encountered for boolean value`);
};

const evalString = (operator: EnableWhenOperator, answerValue: string, value: string | undefined): boolean => {
  if (operator === '=') {
    return answerValue === value;
  } else if (operator === '!=') {
    return answerValue !== value;
  }
  throw new Error(`Unexpected operator ${operator} encountered for boolean value`);
};

const evalEnableWhenItem = (
  enableWhen: QuestionnaireItemEnableWhen,
  values: { [itemLinkId: string]: QuestionnaireResponseItem },
  items: QuestionnaireItem[]
): boolean => {
  const { answerString, answerBoolean, question, operator } = enableWhen;
  // console.log('items', items);
  const itemDef = items.find((item) => {
    return item.linkId === question;
  });
  if (!itemDef) {
    return false;
  }
  if (answerBoolean === undefined && answerString === undefined) {
    // we only need to support these 2 value types so far
    return false;
  }

  if (itemDef.type === 'boolean' && answerBoolean) {
    return evalBoolean(operator, answerBoolean, pickFirstValueFromAnswerItem(values[question], 'boolean'));
  } else if (
    (itemDef.type === 'string' || itemDef.type === 'choice' || itemDef.type === 'open-choice') &&
    answerString
  ) {
    const verdict = evalString(operator, answerString, pickFirstValueFromAnswerItem(values[question]));
    return verdict;
  } else {
    // we only support string and bool atm
    return false;
  }
};

export const evalEnableWhen = (
  item: PMQuestionnaireItem,
  items: PMQuestionnaireItem[],
  values: { [itemLinkId: string]: QuestionnaireResponseItem }
): boolean => {
  const { enableWhen, enableBehavior = 'all' } = item;

  if (enableWhen === undefined || enableWhen.length === 0) {
    return true;
  }

  //console.log('eval enable when', item.linkId);

  if (enableBehavior === 'any') {
    const verdict = enableWhen.some((ew) => {
      const enabled = evalEnableWhenItem(ew, values, items);
      return enabled;
    });
    return verdict;
  } else {
    const verdict = enableWhen.every((ew) => {
      return evalEnableWhenItem(ew, values, items);
    });
    return verdict;
  }
};

// optionVal, if passed will be taken as the value of the field to check requireWhen against,
// otherwise the context is checked for a value using requireWhen.question as the key
export const evalRequired = (item: PMQuestionnaireItem, context: any, questionVal?: any): boolean => {
  if (item.required) {
    return true;
  }

  if (item.requireWhen === undefined) {
    return false;
  }

  const { question, operator, answerString, answerBoolean } = item.requireWhen;

  // for now we assume all linkIds within a form are unique, even accross groups
  // this can be changed later an will be backwards compatible if we come to require
  // structural prcision in the requireWhen feature
  const flattenedContext = flattenItems({ ...context });
  const questionValue = questionVal ?? (makeItemDict(flattenedContext) ?? {})[question];

  if (answerString !== undefined) {
    const comparisonString = questionValue?.answer?.[0]?.valueString ?? questionValue?.valueString;

    if (operator === '=' && comparisonString === answerString) {
      return true;
    }
    if (operator === '!=' && comparisonString !== answerString) {
      return true;
    }
    return false;
  }
  if (answerBoolean !== undefined) {
    const comparisonBool = questionValue?.answer?.[0]?.valueBoolean ?? questionValue?.valueBoolean;

    if (operator === '=' && comparisonBool === answerBoolean) {
      return true;
    }
    if (operator === '!=' && comparisonBool !== answerBoolean) {
      return true;
    }
    return false;
  }
  return false;
};

export const evalItemText = (item: PMQuestionnaireItem, context: any, questionVal?: any): string | undefined => {
  const { textWhen } = item;
  if (textWhen === undefined) {
    return item.text;
  }

  const { question, operator, answerString, substituteText } = textWhen;

  // for now we assume all linkIds within a form are unique, even accross groups
  // this can be changed later an will be backwards compatible if we come to require
  // structural prcision in the textWhen feature
  const flattenedContext = flattenItems({ ...context });
  const questionValue = questionVal ?? (makeItemDict(flattenedContext) ?? {})[question];
  const comparisonString = questionValue?.answer?.[0]?.valueString ?? questionValue?.valueString;

  if (operator === '=' && comparisonString === answerString) {
    return substituteText;
  }
  if (operator === '!=' && comparisonString !== answerString) {
    return substituteText;
  }
  return item.text;
};

interface NestedItem {
  item?: NestedItem[];
}
export const flattenItems = (items: NestedItem[]): any => {
  let itemsList = items as NestedItem[];
  if (typeof items === 'object') {
    itemsList = Object.values(items);
  }
  return itemsList?.flatMap((i) => {
    if (i?.item) {
      return flattenItems(i?.item);
    }
    return i;
  });
};

interface HasLinkId {
  linkId: string;
}
const makeItemDict = (items: HasLinkId[]): { [linkId: string]: any } => {
  return [...items].reduce(
    (accum, cur) => {
      if (cur && cur.linkId) {
        accum[cur.linkId] = { ...cur, linkId: undefined };
      }
      return accum;
    },
    {} as { [linkId: string]: any }
  );
};
