export enum CancellationReasonOptionsUrgentCare {
  'Patient improved' = 'Patient improved',
  'Wait time too long' = 'Wait time too long',
  'Prefer another urgent care provider' = 'Prefer another urgent care provider',
  'Changing PM location' = 'Changing PM location',
  'Changing to PM telemedicine' = 'Changing to PM telemedicine',
  'Financial responsibility concern' = 'Financial responsibility concern',
  'Insurance issue' = 'Insurance issue',
  'Service not offered at PM' = 'Service not offered at PM',
  'Duplicate visit or account error' = 'Duplicate visit or account error',
}

export const CancellationReasonCodesUrgentCare: { [key in CancellationReasonOptionsUrgentCare]: string } = {
  'Patient improved': 'patient-improved',
  'Wait time too long': 'wait-time',
  'Prefer another urgent care provider': 'prefer-another-provider',
  'Changing PM location': 'changing-location',
  'Changing to PM telemedicine': 'changing-telemedicine',
  'Financial responsibility concern': 'financial-concern',
  'Insurance issue': 'insurance-issue',
  'Service not offered at PM': 'service-not-offered',
  'Duplicate visit or account error': 'duplicate-visit-or-account-error',
};
