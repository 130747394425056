import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { APIError, CANT_UPDATE_CANCELED_APT_ERROR, PAST_APPOINTMENT_CANT_BE_MODIFIED_ERROR, VisitType } from 'utils';
import { IntakeFlowPageRoute } from '../App';
import zapehrApi, { AppointmentBasicInfo, AvailableLocationInformation } from '../api/zapehrApi';
import { stethoscope } from '../assets/icons';
import { UCContainer, Schedule } from '../components';
import { useTrackMixpanelEvents } from '../hooks/useTrackMixpanelEvents';
import { DateTime } from 'luxon';
import { useUCZambdaClient } from '../hooks/useUCZambdaClient';
import { useVisitContext } from './ThankYou';
import { ErrorDialog, ErrorDialogConfig } from 'ui-components';
import { useCheckOfficeOpen } from '../hooks/useCheckOfficeOpen';

const Reschedule = (): JSX.Element => {
  const tokenlessZambdaClient = useUCZambdaClient({ tokenless: true });
  const { id: appointmentIDParam } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [slotData, setSlotData] = useState<string[] | undefined>(undefined);
  const [selectedSlot, setSelectedSlot] = useState<string | undefined>(undefined);
  const [appointment, setAppointment] = useState<AppointmentBasicInfo | undefined>();
  const [pageNotFound, setPageNotFound] = useState(false);
  const { isLoading } = useAuth0();
  const navigate = useNavigate();
  const { updateAppointmentStart } = useVisitContext();
  const [errorConfig, setErrorConfig] = useState<ErrorDialogConfig | undefined>(undefined);
  const [submitPending, setSubmitPending] = useState(false);

  const { location, visitType } = useMemo(() => {
    if (appointment) {
      return appointment;
    } else {
      return { location: undefined, visitType: undefined };
    }
  }, [appointment]);
  // Track Welcome page in Mixpanel
  useTrackMixpanelEvents({
    eventName: 'Modify Booking Time',
    visitType: visitType as VisitType,
    loading: loading || isLoading,
    bookingCity: location?.address?.city,
    bookingState: location?.address?.state,
  });

  useEffect(() => {
    const getAppointmentDetails = async (appointmentID: string): Promise<any> => {
      try {
        if (!tokenlessZambdaClient) {
          return;
        }
        setLoading(true);
        const response = await zapehrApi.getAppointmentDetails(tokenlessZambdaClient, appointmentID);
        const appointment = response.appointment;
        const location: AvailableLocationInformation = appointment.location;
        if (!location) {
          // this is basically an internal error that should be thrown by backend.
          // very odd that we'd need to inspect the returned object and throw at this point
          throw new Error('appointment details response missing location');
        }
        setAppointment(appointment);
        const formattedStart = DateTime.fromISO(appointment.start)
          .setZone(location?.timezone)
          .setLocale('en-us')
          .toISO();
        setSelectedSlot(formattedStart);
        const available = response.availableSlots;
        const sortedDatesArray = available.sort((a: string, b: string) => a.localeCompare(b));
        setSlotData(sortedDatesArray);
        setLoading(false);
      } catch (e) {
        setPageNotFound(true);
        console.error('Error validating location: ', e);
      }
    };

    if (appointmentIDParam) {
      void getAppointmentDetails(appointmentIDParam);
    }
  }, [appointmentIDParam, tokenlessZambdaClient]);

  const allAvailableSlots = useMemo(() => {
    const currentSlotTime = DateTime.fromISO(selectedSlot ?? '');
    const currentDateTime = DateTime.now().setZone(location?.timezone);
    const hasNotPassed = currentSlotTime > currentDateTime;
    if (slotData && selectedSlot) {
      const availableSlots =
        hasNotPassed && !slotData.includes(selectedSlot ?? '')
          ? [...(slotData as string[]), selectedSlot ?? '']
          : slotData;
      return availableSlots?.sort((a: string, b: string) => a.localeCompare(b));
    }
    return slotData;
  }, [selectedSlot, location?.timezone, slotData]);

  const { officeHasClosureOverrideToday, officeHasClosureOverrideTomorrow } = useCheckOfficeOpen(location);

  const rescheduleAppointment = useCallback(
    async (slot: string) => {
      if (!tokenlessZambdaClient) {
        throw new Error('zambdaClient is not defined');
      }

      if (!appointmentIDParam) {
        throw new Error('appointment id is missing');
      }

      setSubmitPending(true);

      try {
        const res = await zapehrApi.updateAppointment(tokenlessZambdaClient, {
          appointmentID: appointmentIDParam,
          slot,
        });
        if (res.appointmentID) {
          updateAppointmentStart(slot);
          navigate(`/visit/${res.appointmentID}`);
        } else if (res.availableSlots) {
          setErrorConfig({
            title: 'Sorry, the selected time is no longer available',
            description: 'Please select another time',
          });
          setSlotData(res.availableSlots);
        }
      } catch (e) {
        if ((e as APIError)?.code === CANT_UPDATE_CANCELED_APT_ERROR.code) {
          setErrorConfig({
            title: `This appointment is already canceled.`,
            description: `You cannot reschedule a canceled appointment.`,
            closeButtonText: 'OK',
          });
        } else if ((e as APIError)?.code === PAST_APPOINTMENT_CANT_BE_MODIFIED_ERROR.code) {
          setErrorConfig({
            title: `Appointment can not be modified.`,
            description: `This appointment's start time is in the past.`,
            closeButtonText: 'OK',
          });
        } else {
          setErrorConfig({
            title: 'Unexpected error',
            description: (
              <>
                There was an unexpected error. Please try again and if the error persists{' '}
                <Link to="https://pmpediatriccare.com/contact-us/" target="_blank">
                  contact us
                </Link>
                .
              </>
            ),
          });
        }
      } finally {
        setSubmitPending(false);
      }
    },
    [appointmentIDParam, navigate, tokenlessZambdaClient, updateAppointmentStart]
  );

  // validation for valid state from location resource
  if (pageNotFound) {
    return (
      <UCContainer title="Not Found" bgVariant={IntakeFlowPageRoute.Welcome.path}>
        <Typography variant="body1">
          You have navigated to a page that is not found. To find a PM Pediatric Care location,{' '}
          <a href="https://pmpediatriccare.com/find-care/">please visit our website</a>.
        </Typography>
      </UCContainer>
    );
  }

  const bgVariant = IntakeFlowPageRoute.WelcomeType.path;

  // console.log('selected slot', selectedSlot);

  return (
    <UCContainer
      title={'Modify check-in time'}
      subtitle={loading ? 'Loading...' : `${location?.name}`}
      subtext={loading ? '' : 'Please select a new check-in time.'}
      isFirstPage
      img={stethoscope}
      imgAlt="Stethoscope icon"
      imgWidth={150}
      bgVariant={bgVariant}
    >
      <>
        <Schedule
          slotData={allAvailableSlots}
          setSlotData={setSlotData}
          slotsLoading={loading}
          backButton={true}
          submitLabelAdjective={'Modify to'}
          timezone={location?.timezone || 'America/New_York'}
          existingSelectedSlot={selectedSlot}
          handleSlotSelected={async (slot) => {
            void rescheduleAppointment(slot);
            // todo: there should be a separate get-ready page for a reschedule, or else some way to distinguish
            // between the two flows
            // why was this happening?? the Schedule component would have been performing alternate navigation before
            // this was invoked
            // navigate(`/location/${location?.address?.state}/${location?.slug}/${visitTypeParam}/get-ready`);
          }}
          locationSlug={location?.slug}
          locationState={location?.address?.state}
          forceClosedToday={officeHasClosureOverrideToday}
          forceClosedTomorrow={officeHasClosureOverrideTomorrow}
          submitPending={submitPending}
          markSlotBusy={false}
        />
      </>
      <ErrorDialog
        open={!!errorConfig}
        title={errorConfig?.title || ''}
        description={errorConfig?.description || ''}
        closeButtonText={errorConfig?.closeButtonText ?? 'Select another time'}
        handleClose={() => {
          setErrorConfig(undefined);
        }}
      />
    </UCContainer>
  );
};

export default Reschedule;
