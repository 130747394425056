import { useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress, Dialog, IconButton, Paper, Typography } from '@mui/material';
import { CustomLoadingButton } from 'ui-components';
import CloseIcon from '@mui/icons-material/Close';
import { useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { FormInputType, PageForm, ErrorDialog, ErrorDialogConfig } from 'ui-components';
import {
  CancellationReasonOptionsUrgentCare,
  VisitType,
  getDateComponentsFromISOString,
  getPatientInfoFullName,
} from 'utils';
import { IntakeFlowPageRoute } from '../App';
import { otherColors } from '../IntakeThemeProvider';
import { zapehrApi } from '../api';
import { calendar } from '../assets/icons';
import { CardWithDescriptionAndLink, UCContainer } from '../components';
import { safelyCaptureException } from '../helpers/sentry';
import { Appointment } from './Appointments';
import { useTrackMixpanelEvents } from '../hooks/useTrackMixpanelEvents';
import { getStartingPath } from '../helpers';
import { DateTime } from 'luxon';
import { useUCZambdaClient, ZambdaClient } from '../hooks/useUCZambdaClient';
import { useBookingContext } from './Welcome';
import { useNavigateInFlow } from '../hooks/useNavigateInFlow';
import { Link } from 'react-router-dom';
import { usePreserveQueryParams } from '../hooks/usePreserveQueryParams';

const WelcomeBack = (): JSX.Element => {
  const navigate = useNavigate();
  const zambdaClient = useUCZambdaClient({ tokenless: false });
  const { isAuthenticated, isLoading: authIsLoading, loginWithRedirect } = useAuth0();
  const { patients, patientInfo, visitType, selectedLocation, patientsLoading, setPatientInfo, selectedSlot } =
    useBookingContext();
  const [appointmentsLoading, setAppointmentsLoading] = useState<boolean>(false);
  const [checkInModalOpen, setCheckInModalOpen] = useState<boolean>(false);
  const [appointmentsToCheckIn, setAppointmentsToCheckIn] = useState<Appointment[]>([]);
  const [appointmentsToCancel, setAppointmentsToCancel] = useState<Appointment[]>([]);
  const [bookedAppointment, setBookedAppointment] = useState<Appointment>();
  const [cancellingAppointment, setCancellingAppointment] = useState<boolean>(false);
  const [errorDialog, setErrorDialog] = useState<ErrorDialogConfig | undefined>(undefined);
  const { slug: slugParam, visit_type: visitTypeParam, state: stateParam } = useParams();
  const preserveQueryParams = usePreserveQueryParams();

  const navigateInFlow = useNavigateInFlow();

  useTrackMixpanelEvents({
    eventName: 'Welcome Back',
    visitType: visitType || (visitTypeParam as VisitType),
    loading: isAuthenticated && !authIsLoading ? false : true,
    bookingCity: selectedLocation?.address?.city,
    bookingState: selectedLocation?.address?.state,
  });

  const formElements: FormInputType[] = useMemo(() => {
    return [
      {
        type: 'Radio',
        name: 'patientID',
        label: 'Who is this visit for?',
        defaultValue: patientInfo?.id,
        required: true,
        radioOptions: (patients || [])
          .sort((a, b) => {
            if (!a.firstName) return 1;
            if (!b.firstName) return -1;
            return a.firstName.localeCompare(b.firstName);
          })
          .map((patient) => {
            if (!patient.id) {
              throw new Error('Patient id is not defined');
            }
            return {
              label: getPatientInfoFullName(patient),
              value: patient.id,
              color: otherColors.lightPurpleAlt,
            };
          })
          .concat({
            label: 'Different family member',
            value: 'new-patient',
            color: otherColors.lightPurpleAlt,
          }),
      },
    ];
  }, [patientInfo?.id, patients]);

  const onSubmit = async (data: FieldValues): Promise<void> => {
    let foundPatient = false;
    let patientFirstName = patientInfo?.firstName;
    const currentInfo = patientInfo;
    if (!data.patientID) {
      throw new Error('No patient ID selected!');
    }

    patients &&
      patients.forEach(async (currentPatient) => {
        const {
          year: dobYear,
          month: dobMonth,
          day: dobDay,
        } = getDateComponentsFromISOString(currentPatient?.dateOfBirth);
        if (patientInfo?.id && patientInfo.id === currentPatient.id && currentPatient.id === data.patientID) {
          // console.log('path 1');
          foundPatient = true;
          // don't overrwrite what's alrady in the booking store if we haven't chosen a new patient
        } else if (currentPatient.id === data.patientID) {
          // console.log('path 2');
          foundPatient = true;
          patientFirstName = data.firstName || currentPatient.firstName;
          setPatientInfo({
            id: currentPatient.id,
            newPatient: false,
            firstName: data.firstName || currentPatient.firstName,
            middleName: data.middleName || currentPatient.middleName,
            lastName: data.lastName || currentPatient.lastName,
            dobYear,
            dobDay,
            dobMonth,
            sex: data.sex || currentPatient.sex,
            reasonForVisit: data.reasonForVisit || patientInfo?.reasonForVisit,
            email: data.email || currentPatient.email,
            emailUser: data.emailUser || currentPatient.emailUser,
          });
        }
      });
    if (!foundPatient) {
      // console.log('did not find patient in the patients list');
      if (currentInfo?.id === 'new-patient' && data.patientID === 'new-patient') {
        // is this path ever reached?
        // yes if you click back on from the about the patient page with new patient selected
        // console.log('path 3');
        // console.log('new patient is chosen and have new patient data in the state, will use it');
      } else {
        // console.log('path 4');
        setPatientInfo({
          id: 'new-patient',
          newPatient: true,
          firstName: undefined,
          lastName: undefined,
          dobYear: undefined,
          dobDay: undefined,
          dobMonth: undefined,
          sex: undefined,
          reasonForVisit: undefined,
          email: undefined,
        });
      }
    }

    if (data.patientID !== 'new-patient') {
      if (visitType === VisitType.WalkIn) {
        // If returning patient walks in determine if they need to check in or walk in
        await getAppointmentsAndContinue(data.patientID);
      } else {
        // check for slot hoarding (this condition is ignored locally)
        const bookedAppointmentID = await alreadyBooked(data.patientID);
        if (bookedAppointmentID && window.location.hostname !== 'localhost') {
          setErrorDialog({
            title: 'Error',
            description: (
              <>
                Oops! It appears {patientFirstName} is already registered for a check-in time. To view the existing
                check-in time, click{' '}
                <Link to={`/visit/${bookedAppointmentID}`} target="_blank">
                  here
                </Link>
                .
              </>
            ),
          });
        } else {
          // Continue prebook flow for returning patient
          navigateInFlow('confirm-date-of-birth');
        }
      }
    } else {
      // New patient
      navigateInFlow('patient-information');
    }
  };

  const getAppointmentsAndContinue = async (patientID: string): Promise<void> => {
    try {
      if (!zambdaClient) {
        throw new Error('zambdaClient is not defined');
      }
      setAppointmentsLoading(true);
      let patientAppointments: Appointment[] = [];
      if (patientID) {
        const timezone = selectedLocation?.timezone;
        const today = DateTime.now().setZone(timezone).startOf('day');
        const dayInclusive = today.plus({ day: 1 });
        const response = await zapehrApi.getAppointments(zambdaClient, {
          patientID,
          dateRange: { greaterThan: today.toISO(), lessThan: dayInclusive.toISO() },
        });
        const allPatientAppointments = response.appointments;
        patientAppointments = allPatientAppointments.filter(
          (appointment: Appointment) => !appointment.checkedIn && appointment.status !== 'fulfilled'
        );
      }
      const checkIns = patientAppointments.filter(
        (appointment: Appointment) => appointment.location.name === selectedLocation?.name
      );
      const cancels = patientAppointments.filter(
        (appointment: Appointment) => appointment.location.name !== selectedLocation?.name
      );

      setAppointmentsToCheckIn(checkIns);
      setAppointmentsToCancel(cancels);
      await checkInIfAppointmentBooked(checkIns, cancels);
    } catch (error) {
      console.log(error);
      safelyCaptureException(error);
    } finally {
      setAppointmentsLoading(false);
    }
  };

  const alreadyBooked = async (patientID: string): Promise<string | undefined> => {
    let bookedAppointmentID: string | undefined;
    try {
      if (!zambdaClient) {
        throw new Error('zambdaClient is not defined');
      }
      setAppointmentsLoading(true);
      let patientAppointments: Appointment[] = [];
      if (patientID && selectedSlot && selectedLocation?.timezone) {
        const timezone = selectedLocation.timezone;
        const day = DateTime.fromISO(selectedSlot).setZone(timezone).startOf('day');
        const dayInclusive = day.plus({ day: 1 });
        const response = await zapehrApi.getAppointments(zambdaClient, {
          patientID,
          dateRange: { greaterThan: day.toISO(), lessThan: dayInclusive.toISO() },
        });
        patientAppointments = response.appointments;
      }
      const alreadyBookedAtThisLocation = patientAppointments.filter(
        (appointment: Appointment) =>
          appointment.location.name === selectedLocation?.name && appointment.ecwStatus !== 'CHK'
      );
      bookedAppointmentID = alreadyBookedAtThisLocation[0]?.id;
    } catch (e) {
      console.log('error checking for booked appointments', e);
    } finally {
      setAppointmentsLoading(false);
    }
    return bookedAppointmentID;
  };

  const checkInIfAppointmentBooked = async (checkIns: Appointment[], cancels: Appointment[]): Promise<void> => {
    if (!cancels.length && !checkIns.length) {
      // Continue walk-in flow for returning patient
      navigateInFlow('confirm-date-of-birth');
    } else if (!cancels.length && checkIns.length) {
      // Check in or walk in to location where appointment is booked
      const appointment = checkIns[0];
      const timezone = appointment.location.timezone;
      const now = DateTime.now().setZone(timezone);
      const start = DateTime.fromISO(checkIns[0].start).setZone(timezone);
      const hoursBeforeArrival = start.diff(now).as('hours');

      if (hoursBeforeArrival > 4 && visitType !== VisitType.PostTelemed) {
        // If patient walks in more than 4 hours before their pre-booked slot then cancel the appointment and walk-in
        await handleCancelAppointmentForSelectedLocation(appointment.id, zambdaClient);
        navigateInFlow('confirm-date-of-birth');
      } else {
        // If patient walks in less than 4 hours before their prebook time then check in to the earliest pre-booked appointment
        navigate(`/visit/${appointment.id}/check-in`);
      }
    } else {
      // If appointment location is not selected location then prompt to cancel
      // booked appointment before walking in
      setBookedAppointment(cancels[0]);
      setCheckInModalOpen(true);
    }
  };

  const handleCancelAppointmentForSelectedLocation = async (
    appointmentID: string,
    zambdaClient: ZambdaClient | null
  ): Promise<void> => {
    if (!zambdaClient) {
      // Fail silently
      return;
    }
    setCancellingAppointment(true);
    await zapehrApi.cancelAppointment(
      zambdaClient,
      {
        appointmentID: appointmentID,
        cancellationReason: CancellationReasonOptionsUrgentCare['Duplicate visit or account error'],
        silent: true,
      },
      false
    );
    setCancellingAppointment(false);
  };

  const handleCancelAppointmentForAnotherLocation = async (): Promise<void> => {
    if (zambdaClient && bookedAppointment) {
      setCancellingAppointment(true);
      await zapehrApi.cancelAppointment(
        zambdaClient,
        {
          appointmentID: bookedAppointment.id,
          cancellationReason: CancellationReasonOptionsUrgentCare['Duplicate visit or account error'],
        },
        false
      );
      setCancellingAppointment(false);
    }
    const remainingAppointments = appointmentsToCancel?.slice(1) || [];
    setAppointmentsToCancel(remainingAppointments);
    setCheckInModalOpen(false);
    await checkInIfAppointmentBooked(appointmentsToCheckIn, remainingAppointments);
  };

  const appointmentTimezoneAdjusted = useMemo(() => {
    const bookedAppointmentStart = bookedAppointment?.start;
    if (bookedAppointmentStart) {
      return DateTime.fromISO(bookedAppointmentStart)
        .setZone(bookedAppointment?.location.timezone)
        .setLocale('en-us')
        .toFormat('h:mm a ZZZZ');
    }

    return undefined;
  }, [bookedAppointment]);

  // console.log('loading, authIsLoading', loading, authIsLoading);
  // console.log('patients loading?', patientsLoading);

  if (!isAuthenticated && !authIsLoading) {
    // if the user is not signed in, redirect them to auth0
    loginWithRedirect({
      appState: { target: preserveQueryParams(`/location/${stateParam}/${slugParam}/${visitTypeParam}/patients`) },
    }).catch((error) => {
      throw new Error(`Error calling loginWithRedirect Auth0: ${error}`);
    });
  }

  if (patientsLoading || authIsLoading) {
    return (
      <UCContainer title="Loading..." bgVariant={IntakeFlowPageRoute.WelcomeBack.path}>
        <CircularProgress />
      </UCContainer>
    );
  }

  const onBack = (): void => {
    navigate(getStartingPath(selectedLocation, visitType), { state: { reschedule: true } });
  };

  return (
    <UCContainer
      title="Welcome back!"
      bgVariant={IntakeFlowPageRoute.WelcomeBack.path}
      topOutsideCardComponent={
        visitType === VisitType.WalkIn ? (
          <CardWithDescriptionAndLink
            icon={calendar}
            iconAlt="Calendar icon"
            iconHeight={50}
            mainText="Already reserved a check-in time?"
            textColor={otherColors.white}
            descText="Click here to check-in"
            link={IntakeFlowPageRoute.Appointments.path}
            linkText="Check in"
            bgColor={otherColors.brightPurple}
            marginTop={0}
            marginBottom={2}
          />
        ) : undefined
      }
    >
      <Typography variant="body1" marginTop={2}>
        Please select from the options below so that we can pre-fill your past information for a faster booking.
      </Typography>
      <Typography variant="body1" marginTop={1} marginBottom={2}>
        You'll have the chance to confirm or update previously entered patient information shortly.
      </Typography>
      <PageForm
        formElements={formElements}
        onSubmit={onSubmit}
        controlButtons={{ onBack, loading: appointmentsLoading || cancellingAppointment }}
      />
      <Dialog open={checkInModalOpen} onClose={() => setCheckInModalOpen(false)}>
        <Paper>
          <Box margin={5} maxWidth="sm">
            <Typography variant="h3" color="primary">
              Cancel {bookedAppointment?.firstName || 'Unknown'}'s visit in{' '}
              {bookedAppointment ? `${bookedAppointment.location.name}` : 'Unknown'} at {appointmentTimezoneAdjusted}
            </Typography>
            <Typography marginTop={2}>
              Looks like you have another pre-booked visit for today in {bookedAppointment?.location.name || 'Unknown'}{' '}
              at {appointmentTimezoneAdjusted}. Please cancel it if you wish to continue as a walk-in visit in{' '}
              {selectedLocation?.name || 'Unknown'}.
            </Typography>
            <Box display="flex" justifyContent="flex-end">
              <CustomLoadingButton
                onClick={handleCancelAppointmentForAnotherLocation}
                loading={cancellingAppointment}
                sx={{ marginTop: 2 }}
              >
                Cancel pre-booked visit
              </CustomLoadingButton>
            </Box>
            <IconButton
              aria-label="close"
              onClick={() => setCheckInModalOpen(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: otherColors.scheduleBorder,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Paper>
      </Dialog>
      <ErrorDialog
        open={!!errorDialog}
        title={errorDialog?.title || ''}
        description={errorDialog?.description || ''}
        closeButtonText={'Close'}
        handleClose={() => {
          setErrorDialog(undefined);
        }}
      />
    </UCContainer>
  );
};

export default WelcomeBack;
