import { useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorDialog, ErrorDialogConfig, PageForm } from 'ui-components';
import { APIError, APPOINTMENT_NOT_FOUND_ERROR, CANT_CANCEL_CHECKEDIN_APT_ERROR } from 'utils';
import { IntakeFlowPageRoute } from '../App';
import zapehrApi from '../api/zapehrApi';
import { UCContainer } from '../components';
import { appointmentNotFoundInformation } from '../helpers/information';
import { useTrackMixpanelEvents } from '../hooks/useTrackMixpanelEvents';
import mixpanel from 'mixpanel-browser';
import { useUCZambdaClient } from '../hooks/useUCZambdaClient';
import { useVisitContext } from './ThankYou';
import { useNavigateInFlow } from '../hooks/useNavigateInFlow';

// these are the options for a patient in the UC intake app and are a product requirement for that app
// please don't attempt to extract this to a shared util. if another app has similar or even identical options
// that is fine; enumerate them within that scope and don't sweat any duplication
// https://github.com/masslight/pmp-intake/issues/1907
// https://github.com/masslight/pmp-intake/issues/2709
enum CancelReasonOptions {
  'Patient improved' = 'Patient improved',
  'Wait time too long' = 'Wait time too long',
  'Prefer another urgent care provider' = 'Prefer another urgent care provider',
  'Changing PM location' = 'Changing PM location',
  'Changing to PM telemedicine' = 'Changing to PM telemedicine',
  'Financial responsibility concern' = 'Financial responsibility concern',
  'Insurance issue' = 'Insurance issue',
}

const CancellationReason = (): JSX.Element => {
  const navigate = useNavigate();
  const zambdaClient = useUCZambdaClient({ tokenless: true });
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [cancelErrorDialog, setCancelErrorDialog] = useState<ErrorDialogConfig | undefined>(undefined);
  const { id: appointmentID } = useParams();
  const navigateInFlow = useNavigateInFlow();

  const { appointmentData } = useVisitContext();

  const visitType = useMemo(() => {
    return appointmentData?.appointment?.visitType;
  }, [appointmentData?.appointment?.visitType]);

  const selectedLocation = useMemo(() => {
    return appointmentData?.appointment?.location;
  }, [appointmentData?.appointment?.location]);

  // Track event in Mixpanel
  const eventName = 'Cancellation Reason';
  useTrackMixpanelEvents({
    eventName: eventName,
    visitType: visitType,
    loading: !visitType,
    bookingCity: selectedLocation?.address?.city,
    bookingState: selectedLocation?.address?.state,
  });

  const onSubmit = async (data: FieldValues): Promise<void> => {
    try {
      if (!zambdaClient) {
        throw new Error('zambdaClient is not defined');
      }
      if (!appointmentID) {
        throw new Error('no appointment ID provided');
      }
      setLoading(true);

      await zapehrApi.cancelAppointment(zambdaClient, {
        appointmentID: appointmentID,
        cancellationReason: data.cancellationReason,
      });

      setLoading(false);
      navigateInFlow('cancellation-confirmation');
    } catch (error: any) {
      if ((error as APIError)?.code === CANT_CANCEL_CHECKEDIN_APT_ERROR.code) {
        setCancelErrorDialog({
          title: `Checked in visit`,
          description: `Please proceed to the front desk to have a staff member cancel your visit.`,
          closeButtonText: 'OK',
        });
      } else if ((error as APIError)?.code === APPOINTMENT_NOT_FOUND_ERROR.code) {
        setNotFound(true);
      } else {
        console.log('error', error);
      }
    } finally {
      setLoading(false);
    }
  };

  if (notFound) {
    return (
      <UCContainer
        title={'There was an error canceling this appointment'}
        description={appointmentNotFoundInformation}
        bgVariant={IntakeFlowPageRoute.CheckIn.path}
      >
        <></>
      </UCContainer>
    );
  }

  return (
    <UCContainer title="Why are you canceling?" bgVariant={IntakeFlowPageRoute.CancellationReason.path}>
      <PageForm
        formElements={[
          {
            type: 'Select',
            name: 'cancellationReason',
            label: 'Cancelation reason',
            required: true,
            selectOptions: Object.keys(CancelReasonOptions).map((value) => ({
              label: value,
              value: value,
            })),
          },
        ]}
        controlButtons={{
          loading,
          submitLabel: 'Cancel visit',
          onBack: () => {
            mixpanel.track(eventName, { patientFlow: visitType });
            navigate(-1);
          },
        }}
        onSubmit={onSubmit}
      />
      <ErrorDialog
        open={!!cancelErrorDialog}
        title={cancelErrorDialog?.title || ''}
        description={cancelErrorDialog?.description || ''}
        closeButtonText={cancelErrorDialog?.closeButtonText ?? ''}
        handleClose={() => {
          setCancelErrorDialog(undefined);
        }}
      />
    </UCContainer>
  );
};

export default CancellationReason;
