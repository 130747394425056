import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputBaseComponentProps,
  InputProps,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FieldValues, FormProvider, useForm, useFormContext, useFormState } from 'react-hook-form';
import {
  BoldPurpleInputLabel,
  ControlButtons,
  ControlButtonsProps,
  DescriptionRenderer,
  InputHelperText,
  InputMask,
  LightToolTip,
  LinkRenderer,
  useIntakeThemeContext,
} from 'ui-components';
import {
  PMQuestionnaireItem,
  QuestionnaireFormFields,
  SIGNATURE_FIELDS,
  getInputTypes,
  makeValidationSchema,
  pickFirstValueFromAnswerItem,
} from 'utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { StyledQuestionnaireItem, applyItemStyleOverrides } from './useItemStyleOverrides';
import SelectInput from './components/SelectInput';
import RadioInput from './components/RadioInput';
import RadioListInput from './components/RadioListInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectQuestionnaireItems } from './useSelectItems';
import { usePaperworkContext } from '../../pages/PaperworkPage';
import DateInput from './components/DateInput';
import FileInput from './components/FileInput';
import Markdown from 'react-markdown';
import { useBeforeUnload } from 'react-router-dom';
import { getInputTypeForItem, inputTypeForDisplayItemId } from './utils';
import { usePaperworkFormHelpers } from './useFormHelpers';
import { useItemsToAutoFill, useAutoFillValues } from './useAutofill';
import { AnyObjectSchema } from 'yup';

interface PaperworkGroupOptions {
  bottomComponent?: ReactElement;
  hideControls?: boolean;
  controlButtons?: ControlButtonsProps;
}

interface PaperworkGroupInput {
  items: PMQuestionnaireItem[];
  pageId: string;
  defaultValues?: QuestionnaireFormFields;
  options?: PaperworkGroupOptions;
  onSubmit: (data: QuestionnaireFormFields) => void;
  saveProgress: (data: QuestionnaireFormFields) => void;
}

type FormInputProps = {
  format?: string;
  helperText?: string;
  showHelperTextIcon?: boolean;
  inputBaseProps?: InputBaseComponentProps;
} & InputProps;

interface ItemInputProps {
  item: PMQuestionnaireItem;
  inputProps?: FormInputProps;
  sx?: SxProps<Theme>;
}

interface StyledItemInputProps extends ItemInputProps {
  item: StyledQuestionnaireItem;
}

const DEFAULT_INPUT_BASE_PROPS: InputBaseComponentProps = {
  width: '100%',
};

const makeFormInputPropsForItem = (item: StyledQuestionnaireItem): FormInputProps => {
  const { mask } = item;

  const inputProps = {
    format: undefined,
    infoText: undefined,
    helperText: undefined,
    showHelperTextIcon: false,
    inputBaseProps: {
      ...DEFAULT_INPUT_BASE_PROPS,
      mask,
    },
  };

  // (`input props for item ${item.linkId}`, inputProps);
  return inputProps;
};

const PaperworkGroup: FC<PaperworkGroupInput> = ({
  items,
  pageId,
  defaultValues,
  options = {},
  onSubmit,
  saveProgress,
}) => {
  const [isSavingProgress, setIsSavingProgress] = useState(false);

  const { paperwork, patient, appointment, allItems, saveButtonDisabled } = usePaperworkContext();

  // console.log('group param defaultValues', JSON.stringify(defaultValues));
  // console.log('paperwork', paperwork);
  const validationSchema = makeValidationSchema(items, pageId, {
    values: paperwork,
    items: allItems,
  }) as AnyObjectSchema;
  // console.log('validation schema', validationSchema);
  const methods = useForm({
    mode: 'onSubmit', // onBlur doesnt seem to work but we use onBlur of FormControl in NestedInput to implement the desired behavior
    reValidateMode: 'onChange',
    context: paperwork,
    defaultValues,
    shouldFocusError: true,
    resolver: yupResolver(validationSchema),
  });

  const theme = useTheme();

  const { watch, getValues, setValue, reset, formState } = methods;
  const { isSubmitting, isLoading, errors } = formState;

  useEffect(() => {
    if (items) {
      // console.log('resetting! default values', defaultValues);
      reset({
        ...(defaultValues ?? {}),
      });
    }
  }, [defaultValues, items, reset, pageId]);

  const errorMessage: string | undefined = useMemo(() => {
    const errorKeys = Object.keys(errors);
    const numErrors = errorKeys.length;
    if (numErrors === 0) {
      return undefined;
    }
    const errorItems = items
      .filter((i) => errorKeys.includes(i.linkId) && i.text !== undefined)
      .map((i) => `"${i.text}"`);
    if (numErrors === errorItems.length) {
      if (numErrors > 1) {
        return `Please fix the errors in the following fields to proceed: ${errorItems.join(', ')}`;
      } else {
        return `Please fix the error in the ${errorItems[0]} field to proceed`;
      }
    } else if (numErrors === 1) {
      return 'Please fix the error in the field above to proceed';
    } else {
      return `Please fix the errors in the ${numErrors} fields above to proceed.`;
    }
  }, [errors, items]);

  const watchedFields = watch();
  const formValues: FieldValues = useMemo(() => {
    return { ...getValues(), ...watchedFields };
  }, [getValues, watchedFields]);
  const allFields: FieldValues = useMemo(() => {
    // console.log('paperwork', paperwork);
    // console.log('form values', formValues);
    const flatPaperwork = paperwork.flatMap((page) => page.item ?? []);
    const paperworkObj = flatPaperwork.reduce((accum, curr) => {
      accum[curr.linkId] = { ...curr };
      return accum;
    }, {} as any);
    return { ...paperworkObj, ...formValues };
  }, [paperwork, formValues]);

  // console.log('formValues', formValues);

  useBeforeUnload(() => {
    saveProgress(formValues);
  });

  // console.log('patient-birthdate', formValues['patient-birthdate']);

  const styledItems = (() => {
    const selectedItems = selectQuestionnaireItems(items, allItems, allFields);
    return applyItemStyleOverrides(selectedItems, allItems, patient, appointment, allFields);
  })();

  const autoFillableFields = useItemsToAutoFill(items, allFields);
  // console.log('autofillable fields', autoFillableFields, allFields);
  useAutoFillValues(autoFillableFields, setValue, allFields, formValues);
  const submitHandler = useCallback(async () => {
    setIsSavingProgress(true);
    const { handleSubmit } = methods;
    await handleSubmit(onSubmit)();
    setIsSavingProgress(false);
  }, [methods, onSubmit]);

  const { bottomComponent, hideControls, controlButtons } = options;

  const swizzledCtrlButtons = useMemo(() => {
    const baseStuff = controlButtons ?? {};
    return {
      ...baseStuff,
      submitDisabled: baseStuff.loading || isLoading || saveButtonDisabled,
      onSubmit: submitHandler,
    };
  }, [controlButtons, isLoading, saveButtonDisabled, submitHandler]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={submitHandler}>
        <Grid container spacing={1}>
          {styledItems.map((item) => {
            if (item.type === 'display') {
              return <FormDisplayField item={item} key={`FDF-${item.linkId}`} />;
            } else {
              const dependency = item.requireWhen ? formValues[item.requireWhen.question] : undefined;
              return (
                <NestedInput
                  key={`NI-${item.linkId}`}
                  item={item}
                  inputProps={makeFormInputPropsForItem(item)}
                  dependency={dependency}
                />
              );
            }
          })}
        </Grid>
        <div id="page-form-inner-form" />
        {bottomComponent}
        {errorMessage && (
          <FormHelperText
            id={'form-error-helper-text'}
            sx={{ textAlign: 'right', color: theme.palette.error.main, gap: 0, mt: 1 }}
          >
            {errorMessage}
          </FormHelperText>
        )}
        {!hideControls && <ControlButtons {...swizzledCtrlButtons} loading={isSavingProgress || isSubmitting} />}
      </form>
    </FormProvider>
  );
};

// this probably has a more specific type but not sure what it is right now
const makeStyles = (): any => {
  const signatureFont = 'Dancing Script, Tangerine, Bradley Hand, Brush Script MT, sans-serif';
  return {
    signatureStyles: {
      input: {
        fontFamily: signatureFont,
        fontSize: '20px',
        fontWeight: 500,
      },
      'input::placeholder': {
        fontFamily: signatureFont,
      },
    },
  };
};

interface NestedInputProps extends StyledItemInputProps {
  dependency?: any;
}

const NestedInput: FC<NestedInputProps> = (props) => {
  const { errors } = useFormState();
  const { item, inputProps, sx = {}, dependency } = props;
  const { helperText, showHelperTextIcon } = inputProps || {};
  const { otherColors } = useIntakeThemeContext();
  const { trigger } = useFormContext();
  const [isFocused, setIsFocused] = useState(false);

  const error = !!errors[item.linkId];

  useEffect(() => {
    if (error && dependency) {
      void trigger(item.linkId);
    }
  }, [error, dependency, trigger, item.linkId]);

  return (
    <Grid item xs={12} md={item.width} key={item.linkId}>
      <Controller
        name={item.linkId}
        render={(renderProps) => (
          <FormControl
            variant="standard"
            required={item.isRequired}
            error={error}
            fullWidth={item.isFullWidth}
            hiddenLabel={true}
            disabled={item.displayStrategy === 'protected'}
            onBlur={() => {
              if (getInputTypeForItem(item) === 'Text') {
                void trigger(item.linkId);
              }
              setIsFocused(false);
            }}
            onChange={() => {
              if (error) {
                void trigger(item.linkId);
              }
            }}
            onFocus={() => setIsFocused(true)}
            margin={'dense'}
            sx={{
              width: '100%',
              ...sx,
              '& .MuiInputBase-root': {
                marginTop: '0px',
              },
            }}
          >
            <BoldPurpleInputLabel
              id={`${item.linkId}-label`}
              htmlFor={`${item.linkId}-label`}
              sx={(theme) => ({
                ...(item.hideControlLabel ? { display: 'none' } : { whiteSpace: 'pre-wrap', position: 'unset' }),
                color: isFocused ? theme.palette.primary.main : theme.palette.primary.dark,
              })}
            >
              {item.infoText ? (
                <Tooltip enterTouchDelay={0} title={item.infoText} placement="top" arrow>
                  <Box>
                    {item.text}
                    <IconButton>
                      <InfoOutlinedIcon sx={{ fontSize: '18px', color: 'secondary.main' }} />
                    </IconButton>
                  </Box>
                </Tooltip>
              ) : (
                item.text
              )}
            </BoldPurpleInputLabel>
            <FormInputField renderProps={renderProps} itemProps={props} />
            {item.secondaryInfoText ? (
              <LightToolTip
                title={item.secondaryInfoText}
                placement="top"
                enterTouchDelay={0}
                backgroundColor={otherColors.toolTipGrey}
                color={otherColors.black}
              >
                <Box
                  sx={{
                    color: otherColors.scheduleBorder,
                    width: 'fit-content',
                    display: 'flex',
                    marginTop: 0.5,
                    cursor: 'default',
                  }}
                >
                  <InfoOutlinedIcon style={{ height: '16px', width: '16px' }} />
                  <Typography sx={{ fontSize: '14px', marginLeft: 0.5 }}>Why do we ask this?</Typography>
                </Box>
              </LightToolTip>
            ) : null}
            <InputHelperText
              name={item.linkId}
              errors={errors}
              helperText={helperText}
              showHelperTextIcon={showHelperTextIcon}
            />
          </FormControl>
        )}
      />
    </Grid>
  );
};

interface GetFormInputFieldProps {
  itemProps: StyledItemInputProps;
  renderProps: any; // do better
}

const FormInputField: FC<GetFormInputFieldProps> = ({ itemProps, renderProps }): ReactElement => {
  const { item, inputProps } = itemProps;
  const { inputBaseProps, inputMode } = inputProps || { disableUnderline: true };
  const {
    field: { value, onChange, ref },
    formState: { errors, defaultValues },
  } = renderProps;
  const inputType = getInputTypeForItem(item);
  const { otherColors } = useIntakeThemeContext();
  const myInputComponent = inputBaseProps?.mask ? (InputMask as any) : 'input';

  const styles = useMemo(() => {
    return makeStyles();
  }, []);

  const inputSX = useMemo(() => {
    return SIGNATURE_FIELDS.includes(item.linkId)
      ? { ...styles.inputStyles, ...styles.signatureStyles }
      : styles.inputStyles;
  }, [item.linkId, styles.inputStyles, styles.signatureStyles]);

  const { setFocus } = useFormContext();
  const {
    onChange: smartOnChange,
    inputRef,
    fieldId,
    value: unwrappedValue,
  } = usePaperworkFormHelpers(item, value, onChange);

  useEffect(() => {
    const hasError = errors[item.linkId];
    if (hasError !== undefined) {
      setFocus(fieldId, { shouldSelect: true });
    }
  }, [errors, fieldId, item.linkId, setFocus]);

  const answerOptions = useMemo(() => {
    const options = item.answerOption ?? [];
    if (!item.randomize) {
      return options;
    }

    // Durstenfeld shuffle alogrithm
    // https://stackoverflow.com/a/12646864
    for (let i = options.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [options[i], options[j]] = [options[j], options[i]];
    }
    return options;
  }, [item.answerOption, item.randomize]);

  return (() => {
    switch (inputType) {
      case 'Text':
        return (
          <TextField
            id={fieldId}
            value={unwrappedValue}
            type={getInputTypes(item.linkId) === 'tel' ? 'tel' : 'text'}
            inputMode={inputMode ?? (item.type === 'integer' || item.type === 'decimal' ? 'numeric' : 'text')}
            aria-labelledby={`${item.linkId}-label`}
            aria-describedby={`${item.linkId}-helper-text`}
            inputProps={inputBaseProps}
            placeholder={item.placeholder}
            required={item.required}
            onChange={smartOnChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              multiline: item.multiline,
              minRows: item.minRows,
              inputComponent: myInputComponent,
              disabled: item.displayStrategy !== 'enabled',
              ref: inputRef,
              error: !!errors[item.linkId],
            }}
            // this also overrides explicitly passed in sx in inputProps...
            sx={{
              ...inputSX,
              '.MuiOutlinedInput-root': {
                borderRadius: '8px',
                height: 'auto',
                width: '100%',
                padding: item.minRows ? '12px 16px' : '2px 2px',
                '&.Mui-focused': {
                  boxShadow: '0 -0.5px 0px 3px rgba(77, 21, 183, 0.25)',
                  '& fieldset': {
                    borderWidth: '1px',
                  },
                },
              },
            }}
            size="small"
          />
        );
      case 'Select':
        if (!item.answerOption) {
          throw new Error('No selectOptions given in select');
        }
        return (
          <SelectInput
            name={fieldId}
            value={unwrappedValue}
            placeholder={item.placeholder}
            defaultValue={(defaultValues && pickFirstValueFromAnswerItem(defaultValues[item.linkId])) ?? null}
            required={item.required}
            options={item.answerOption}
            inputRef={ref}
            onChange={smartOnChange}
          />
        );
      case 'Checkbox':
        return (
          <FormControlLabel
            label={<Markdown components={{ p: DescriptionRenderer, a: LinkRenderer }}>{item.text}</Markdown>}
            sx={{ pt: item.hideControlLabel ? 0 : 1, alignItems: 'flex-start', margin: '0px' }}
            control={
              <Checkbox
                checked={unwrappedValue}
                color="primary"
                style={{ borderRadius: '4px' }}
                aria-label={`${item.linkId}-label`}
                sx={{
                  alignSelf: 'flex-start',
                  width: '18px',
                  height: '18px',
                  display: 'inline-flex',
                  marginTop: '0px',
                  marginRight: '10px',
                  '&.MuiCheckbox-root': {
                    borderRadius: '4px',
                  },
                  '&.Mui-checked': {
                    color: otherColors.purple,
                    borderRadius: '4px',
                    outline: '1px solid #4D15B7',
                  },
                }}
                onChange={smartOnChange}
                required={item.required}
              />
            }
          />
        );
      case 'Radio':
        return (
          <RadioInput
            name={fieldId}
            value={unwrappedValue}
            required={item.required}
            options={answerOptions}
            onChange={smartOnChange}
          />
        );
      case 'Radio List':
        return (
          <RadioListInput
            name={fieldId}
            value={unwrappedValue}
            required={item.required}
            options={answerOptions}
            onChange={smartOnChange}
          />
        );
      case 'Date':
        return <DateInput item={item} />;
      case 'Attachment':
        return (
          <FileInput
            fileName={item.linkId}
            fieldName={fieldId}
            value={unwrappedValue}
            onChange={smartOnChange}
            description={item.attachmentText ?? ''}
          />
        );
      default:
        return <></>;
    }
  })();
};

interface FormDisplayFieldProps {
  item: StyledQuestionnaireItem;
}

const FormDisplayField: FC<FormDisplayFieldProps> = ({ item }): ReactElement => {
  const displayType = inputTypeForDisplayItemId(item.linkId);
  const element = useMemo(() => {
    switch (displayType) {
      case 'Header 4':
        return (
          <Box mb={1} key={`form-display-H4-${item.linkId}-${item.text}`}>
            <Typography variant="h4" color="primary">
              {item.text}
            </Typography>
          </Box>
        );
      case 'Header 3':
        return (
          <Box mb={1} key={`form-display-H3-${item.linkId}-${item.text}`}>
            <Typography variant="h3" color="primary">
              {item.text}
            </Typography>
          </Box>
        );
      case 'Description':
        return (
          <Typography
            variant="body1"
            key={`form-display-body1-${item.linkId}-${item.text}`}
            sx={{ paddingBottom: '10px' }}
          >
            {item.text}
          </Typography>
        );
      default:
        return <></>;
    }
  }, [displayType, item.linkId, item.text]);
  return (
    <Grid item xs={12} md={item.width} key={`form-display-field-${item.linkId}`} paddingLeft="0px">
      {element}
    </Grid>
  );
};

export default PaperworkGroup;
