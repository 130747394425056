import { useAuth0 } from '@auth0/auth0-react';
import {
  Cancel,
  CheckCircle,
  CreateOutlined,
  EditCalendarOutlined,
  EventBusyOutlined,
  InfoOutlined,
  WatchLater,
} from '@mui/icons-material';
import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { DATETIME_FULL_NO_YEAR, VisitType, ECWVisitStatus } from 'utils';
import { IntakeFlowPageRoute } from '../App';
import { otherColors, palette } from '../IntakeThemeProvider';
import { zapehrApi } from '../api';
import { LinkedButtonWithIcon } from '../components';
import { UCContainer } from '../components';
import { useTrackMixpanelEvents } from '../hooks/useTrackMixpanelEvents';
import { useUCZambdaClient } from '../hooks/useUCZambdaClient';
import { useIntakeCommonStore } from '../features/common';
import { usePreserveQueryParams } from '../hooks/usePreserveQueryParams';

export interface Appointment {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  start: string;
  status: string;
  location: { name: string; slug: string; state: string; timezone: string };
  paperworkComplete: boolean;
  checkedIn: boolean;
  visitType: string;
  ecwStatus: ECWVisitStatus;
}

const Appointments = (): JSX.Element => {
  const [appointments, setAppointments] = useState<Appointment[] | undefined>(undefined);
  const zambdaClient = useUCZambdaClient({ tokenless: false });

  const { lastUsedLocationPath } = useIntakeCommonStore();
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const preserveQueryParams = usePreserveQueryParams();

  // Track event in Mixpanel only for authenticated page views since
  // user will immediately be redirected to login if unathenticated
  // there is no specific appointment/location/visit type associated with this page so
  // we send undefined for visitType, bookingCity, bookingState
  useTrackMixpanelEvents({
    eventName: 'Appointments',
    visitType: undefined,
    loading: isAuthenticated && !isLoading ? false : true,
    bookingCity: undefined,
    bookingState: undefined,
  });

  // the appointments page should not be in charge of clearing all this state
  useEffect(() => {
    async function getAppointments(): Promise<void> {
      if (zambdaClient) {
        const res = await zapehrApi.getAppointments(zambdaClient);
        const appointments = res.appointments;
        setAppointments(appointments);
      }
    }
    if (!isLoading && isAuthenticated) {
      getAppointments().catch((error) => {
        console.log(error);
      });
    } else if (!isLoading) {
      loginWithRedirect({ redirectUri: preserveQueryParams(`${window.location.origin}/visits`) }).catch((error) => {
        throw new Error(`Error calling loginWithRedirect Auth0: ${error}`);
      });
    }
  }, [zambdaClient, isLoading, isAuthenticated, loginWithRedirect, preserveQueryParams]);

  return (
    <UCContainer
      title="Reserved check-in times"
      description="Manage your active check-in times here"
      bgVariant={IntakeFlowPageRoute.Appointments.path}
    >
      {appointments ? (
        appointments.length === 0 ? (
          <Typography variant="body1">You don't have any appointments scheduled.</Typography>
        ) : (
          <Box>
            {appointments.map((appointment) => {
              return (
                <Grid
                  key={appointment.id}
                  container
                  sx={{ backgroundColor: otherColors.primaryBackground, borderRadius: 2, padding: 2, marginBottom: 2 }}
                  className="appointment"
                >
                  <Grid item xs={12}>
                    <Typography variant="h3" color="secondary.main" marginBottom={2} className="name">
                      {appointment.firstName} {appointment.middleName} {appointment.lastName}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={8} textAlign={{ xs: 'center', md: 'start' }}>
                    <Typography variant="body2" color="secondary.main" className="date">
                      {DateTime.fromISO(appointment.start)
                        .setZone(appointment.location.timezone)
                        .setLocale('en-us')
                        .toFormat(DATETIME_FULL_NO_YEAR)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'end' }}>
                    {appointment.visitType !== VisitType.WalkIn &&
                      appointment.visitType !== VisitType.PostTelemed &&
                      !appointment.checkedIn && (
                        <LinkedButtonWithIcon
                          to={`/visit/${appointment.id}/reschedule`}
                          text="Modify"
                          btnVariant="text"
                          startIcon={<EditCalendarOutlined />}
                          className="modify-button"
                        />
                      )}
                  </Grid>

                  <Grid item xs={12} md={8} textAlign={{ xs: 'center', md: 'start' }}>
                    <Typography variant="body2" color="secondary.main" className="location">
                      {appointment.location.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'end' }}>
                    {!appointment.checkedIn && appointment.visitType !== VisitType.PostTelemed && (
                      <LinkedButtonWithIcon
                        to={`/visit/${appointment.id}/cancel`}
                        text="Cancel"
                        btnVariant="text"
                        startIcon={<EventBusyOutlined />}
                        className="cancel-button"
                      />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Divider sx={{ marginBottom: 2 }} />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={appointment.paperworkComplete ? 7 : 6}
                    textAlign={{ xs: 'center', md: 'start' }}
                    display="flex"
                    justifyContent="start"
                    className="paperwork"
                  >
                    {appointment.paperworkComplete ? (
                      <>
                        <CheckCircle sx={{ marginRight: 0.5 }} htmlColor={otherColors.darkGreen} />
                        <Typography color={palette.secondary.main} variant="subtitle1">
                          Your paperwork is complete
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Cancel sx={{ marginRight: 0.5 }} htmlColor={otherColors.cancel} />
                        <Typography color={palette.secondary.main} variant="subtitle1">
                          Paperwork incomplete
                        </Typography>
                      </>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={appointment.paperworkComplete ? 5 : 6}
                    textAlign={{ xs: 'center', md: 'end' }}
                    display="flex"
                    justifyContent="end"
                  >
                    <LinkedButtonWithIcon
                      to={`/visit/${appointment.id}`}
                      text={appointment.paperworkComplete ? 'Edit' : 'Complete paperwork'}
                      btnVariant="text"
                      startIcon={<CreateOutlined htmlColor={palette.primary.main} />}
                      className="paperwork-button"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    textAlign={{ xs: 'center', md: 'start' }}
                    display="flex"
                    justifyContent="start"
                    className="check-in"
                  >
                    {appointment.checkedIn ? (
                      <>
                        <CheckCircle sx={{ marginRight: 0.5 }} htmlColor={otherColors.darkGreen} />
                        <Typography color={palette.secondary.main} variant="subtitle1">
                          You are checked in
                        </Typography>
                      </>
                    ) : (
                      <>
                        <WatchLater sx={{ marginRight: 0.5 }} htmlColor={palette.primary.main} />
                        <Typography color={palette.secondary.main} variant="subtitle1">
                          Check in when you arrive
                        </Typography>
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6} textAlign={{ xs: 'center', md: 'end' }} display="flex" justifyContent="end">
                    {appointment.checkedIn ? (
                      <>
                        <LinkedButtonWithIcon
                          to={`/visit/${appointment.id}`}
                          text="Visit details"
                          btnVariant="text"
                          startIcon={<InfoOutlined />}
                          className="visitdetails-button"
                        />
                      </>
                    ) : (
                      <LinkedButtonWithIcon
                        to={`/visit/${appointment.id}/check-in`}
                        text="Check in"
                        btnVariant="contained"
                        className="checkin-button"
                      />
                    )}
                  </Grid>
                </Grid>
              );
            })}
            {lastUsedLocationPath && (
              <Grid container>
                <Grid item xs={12}>
                  <LinkedButtonWithIcon
                    to={lastUsedLocationPath}
                    text="Register another patient"
                    btnVariant="outlined"
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        )
      ) : (
        <CircularProgress />
      )}
    </UCContainer>
  );
};

export default Appointments;
