import { useFormContext } from 'react-hook-form';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { InputProps } from '@mui/material';
import DateInputFields from 'ui-components/lib/components/form/DateInputFields';
import { StyledQuestionnaireItem } from '../useItemStyleOverrides';
import { getComponentKeysFromDateGroup } from 'utils';

type DateInputProps = {
  item: StyledQuestionnaireItem;
  helperText?: string;
  showHelperTextIcon?: boolean;
  required?: boolean;
} & InputProps;

const DateInput: FC<DateInputProps> = ({ item }) => {
  const { setValue, watch, getValues, register, formState, trigger } = useFormContext();

  const disabled = item.displayStrategy === 'protected';

  const { dayId, monthId, yearId, dayLinkId, yearLinkId, monthLinkId } = useMemo(() => {
    // these are the paths to the values manipulated by the 3 date component inputs
    const dayId = `${item.linkId}.item.0.answer.0.valueString`;
    const monthId = `${item.linkId}.item.1.answer.0.valueString`;
    const yearId = `${item.linkId}.item.2.answer.0.valueString`;
    // these are the linkids of the nested fields on the date group questionnaire item
    const { dayKey: dayLinkId, monthKey: monthLinkId, yearKey: yearLinkId } = getComponentKeysFromDateGroup(item);
    return { dayId, monthId, yearId, dayLinkId, yearLinkId, monthLinkId };
  }, [item]);

  const dayVal = watch(dayId, getValues(dayId) ?? '');
  const monthVal = watch(monthId, getValues(monthId) ?? '');
  const yearVal = watch(yearId, getValues(yearId) ?? '');

  const { ref: inputRef } = register(item.linkId);

  const dateError = !!formState.errors[item.linkId];
  useEffect(() => {
    if (dateError) {
      void trigger(item.linkId);
    }
  }, [dateError, item.linkId, dayVal, monthVal, yearVal, trigger]);

  const setNestedValue = useCallback(
    (id: number, value: any) => {
      const path = `${item.linkId}.item.${id}`;
      let linkId = dayLinkId;
      if (id === 1) {
        linkId = monthLinkId;
      } else if (id === 2) {
        linkId = yearLinkId;
      }
      setValue(path, { linkId, answer: [{ valueString: value }] });
    },
    [dayLinkId, item.linkId, monthLinkId, setValue, yearLinkId]
  );

  return (
    <DateInputFields
      required={item.required}
      error={dateError}
      label={undefined}
      disabled={disabled}
      selectedDay={dayVal}
      selectedMonth={monthVal}
      selectedYear={yearVal}
      inputRef={inputRef}
      setSelectedDay={(day) => {
        setNestedValue(0, day);
      }}
      setSelectedMonth={(month) => {
        setNestedValue(1, month);
      }}
      setSelectedYear={(year) => {
        setNestedValue(2, year);
      }}
      key={`DateInput-${item.linkId}`}
    />
  );
};

export default DateInput;
