import { BatchInputPostRequest, FhirClient } from '@zapehr/sdk';
import { Operation } from 'fast-json-patch';
import {
  Address,
  Appointment,
  CodeableConcept,
  Coding,
  Consent,
  ContactPoint,
  Coverage,
  DocumentReference,
  Encounter,
  HumanName,
  Location,
  Patient,
  Period,
  Person,
  Practitioner,
  QuestionnaireResponse,
  Reference,
  RelatedPerson,
  Resource,
  Task,
  TaskInput,
} from 'fhir/r4';
import { DateTime } from 'luxon';
import { SyncTaskCoding, SyncTaskIndicator, UCTaskCoding } from '../ecw-sync';
import { removePrefix } from '../helpers';
import { PatientInfo, UserType, VisitType } from '../types';
import { PractitionerLicense, PractitionerQualificationCode } from '../types/telemed';
import {
  FHIR_EXTENSION,
  PRACTITIONER_QUALIFICATION_CODE_SYSTEM,
  PRACTITIONER_QUALIFICATION_EXTENSION_URL,
  PRACTITIONER_QUALIFICATION_STATE_SYSTEM,
  PRIVATE_EXTENSION_BASE_URL,
} from './constants';
import { PMP_MODULE } from './moduleIdentification';

export function getPatientFirstName(patient: Patient): string | undefined {
  return getFirstName(patient);
}

export function getPatientLastName(patient: Patient): string | undefined {
  return getLastName(patient);
}

export function getFirstName(individual: Patient | Practitioner | RelatedPerson | Person): string | undefined {
  return individual.name?.[0]?.given?.[0];
}

export function getMiddleName(individual: Patient | Practitioner | RelatedPerson | Person): string | undefined {
  return individual.name?.[0].given?.[1];
}

export function getLastName(individual: Patient | Practitioner | RelatedPerson | Person): string | undefined {
  return individual.name?.[0]?.family;
}

export function getFullName(individual: Patient | Practitioner | RelatedPerson | Person): string {
  const firstName = getFirstName(individual);
  const middleName = getMiddleName(individual);
  const lastName = getLastName(individual);
  return `${firstName}${middleName ? ` ${middleName}` : ''} ${lastName}`;
}

export function getPatientInfoFullName(patient: PatientInfo): string {
  const { firstName, middleName, lastName } = patient;
  return `${firstName}${middleName ? ` ${middleName}` : ''} ${lastName}`;
}

export function getPatientChosenName(patient: PatientInfo, lowercaseP?: boolean): string {
  return patient.chosenName ? patient.chosenName : patient.firstName ?? `${lowercaseP ? 'p' : 'P'}atient`;
}

export function getPatientInfoFullNameUsingChosen(patient: PatientInfo): string {
  const { middleName, lastName } = patient;
  return `${getPatientChosenName(patient)}${middleName ? ` ${middleName}` : ''} ${lastName}`;
}

export function getPractitionerNPI(practitioner: Practitioner): string | undefined {
  return practitioner.identifier?.find((ident) => {
    return ident.system === 'http://hl7.org.fhir/sid/us-npi';
  })?.value;
}

export const codingsEqual = (coding1: Coding, coding2: Coding): boolean => {
  const systemsAreEqual = coding1.system === coding2.system;
  const codesAreEqual = coding1.code === coding2.code;

  return systemsAreEqual && codesAreEqual;
};

export const codingContainedInList = (coding: Coding, codingList: Coding[]): boolean => {
  return codingList.reduce((haveMatch, currentCoding) => {
    return haveMatch || codingsEqual(coding, currentCoding);
  }, false);
};

export const findPatientForAppointment = (appointment: Appointment, patients: Patient[]): Patient | undefined => {
  const { participant } = appointment;
  if (!participant) {
    return undefined;
  }
  return patients.find((pat) => {
    return participant.some((part) => {
      const { actor } = part;
      if (actor && actor.reference) {
        const [type, appPatientId] = actor.reference.split('/');
        if (type !== 'Patient') {
          return false;
        }
        // console.log('appPatientId', appPatientId);
        return appPatientId === pat.id;
      }
      return false;
    });
  });
};

export const findLocationForAppointment = (appointment: Appointment, locations: Location[]): Location | undefined => {
  const { participant } = appointment;
  if (!participant) {
    return undefined;
  }
  return locations.find((loc) => {
    return participant.some((part) => {
      const { actor } = part;
      if (actor && actor.reference) {
        const [type, appLocationId] = actor.reference.split('/');
        if (type !== 'Location') {
          return false;
        }
        // console.log('appLocationId', appLocationId);
        return appLocationId === loc.id;
      } else {
        console.log('no actor?', JSON.stringify(actor));
      }
      return false;
    });
  });
};

export const findEncounterForAppointment = (
  appointment: Appointment,
  encounters: Encounter[]
): Encounter | undefined => {
  // Go through encounters and find the one with appointment
  return encounters.find(
    (encounter) =>
      encounter.appointment?.find((appRef) => {
        const { reference } = appRef;
        if (!reference) {
          return false;
        }
        const [_, refId] = reference.split('/');
        return refId && refId === appointment.id;
      })
  );
};

export const findPatientForEncounter = (encounter: Encounter, patients: Patient[]): Patient | undefined => {
  const patientRef = encounter.participant?.find((part) => part.individual?.reference?.includes('Patient/'))?.individual
    ?.reference;
  const patientId = removePrefix('Patient/', patientRef ?? '');
  if (patientId) return patients.find((patient) => patient.id === patientId);
  return undefined;
};

export const findRelatedPersonForPatient = (
  patient: Patient,
  relatedPersons: RelatedPerson[]
): RelatedPerson | undefined => {
  return relatedPersons.find(
    (relatedPerson) => removePrefix('Patient/', relatedPerson.patient.reference ?? '') === patient.id
  );
};

export const resourceHasTag = (resource: Resource, tag: Coding): boolean => {
  const tags = resource.meta?.tag ?? [];
  return tags.some((t) => {
    return t.system === tag.system && t.code === tag.code;
  });
};

export const isPrebookAppointment = (appointment: Appointment): boolean => {
  const typeCoding = appointment.appointmentType?.text;
  return typeCoding === VisitType.PostTelemed || typeCoding === VisitType.PreBook;
};

export const isPostTelemedAppointment = (appointment: Appointment): boolean => {
  const typeCoding = appointment.appointmentType?.text;
  return typeCoding === VisitType.PostTelemed;
};

export function getPatientContactEmail(patient: Patient): string | undefined {
  const formUser = patient.extension?.find((ext) => ext.url === `${PRIVATE_EXTENSION_BASE_URL}/form-user`)?.valueString;
  if (formUser === 'Parent/Guardian') {
    return patient.contact
      ?.find(
        (contactTemp) =>
          contactTemp.relationship?.find(
            (relationshipTemp) =>
              relationshipTemp.coding?.find(
                (codingTemp) => codingTemp.system === `${PRIVATE_EXTENSION_BASE_URL}/relationship`
              )
          )
      )
      ?.telecom?.find((telecomTemp) => telecomTemp.system === 'email')?.value;
  } else {
    return patient.telecom?.find((telecomTemp) => telecomTemp.system === 'email')?.value;
  }
}

export function getOtherOfficesForLocation(location: Location): { display: string; url: string }[] {
  const rawExtensionValue = location?.extension?.find(
    (extensionTemp) => extensionTemp.url === 'https://fhir.zapehr.com/r4/StructureDefinitions/other-offices'
  )?.valueString;
  if (!rawExtensionValue) {
    console.log("Location doesn't have other-offices extension");
    return [];
  }

  let parsedExtValue: { display: string; url: string }[] = [];
  try {
    parsedExtValue = JSON.parse(rawExtensionValue);
  } catch (_) {
    console.log('Location other-offices extension is formatted incorrectly');
    return [];
  }

  return parsedExtValue;
}

export interface CreateDocucmentReferenceInput {
  docInfo: { contentURL: string; title: string; mimeType: string }[];
  type: CodeableConcept;
  dateCreated: string;
  references: object;
  fhirClient: FhirClient;
  pmpModule: PMP_MODULE;
  generateUUID?: () => string;
  taskContext?: { patientId: string; encounterId?: string };
}

export async function createDocumentReference(input: CreateDocucmentReferenceInput): Promise<DocumentReference> {
  const { docInfo, type, dateCreated, references, fhirClient, pmpModule, taskContext, generateUUID } = input;
  try {
    console.log('creating new document reference resource');
    const writeDRFullUrl = generateUUID ? generateUUID() : undefined;
    const writeDocRefReq: BatchInputPostRequest = {
      method: 'POST',
      fullUrl: writeDRFullUrl,
      url: '/DocumentReference',
      resource: {
        resourceType: 'DocumentReference',
        // todo: create sync task
        meta: {
          tag: [{ code: pmpModule }],
        },
        date: dateCreated,
        status: 'current',
        type: type,
        content: docInfo.map((tempInfo) => {
          return { attachment: { url: tempInfo.contentURL, contentType: tempInfo.mimeType, title: tempInfo.title } };
        }),
        ...references,
      },
    };
    const additionalTasks: BatchInputPostRequest[] = [];
    console.log(`Task context: ${JSON.stringify(taskContext)}, DocumentReference fullUrl: ${writeDRFullUrl}`);
    if (taskContext && writeDRFullUrl) {
      console.log('Creating task to sync consent with redox');
      const writeTaskReq: BatchInputPostRequest = {
        method: 'POST',
        url: '/Task',
        resource: makeSyncTask({
          documentRef: writeDRFullUrl,
          context: taskContext,
          taskCoding: SyncTaskIndicator.syncDocumentConsent,
        }),
      };
      additionalTasks.push(writeTaskReq);
    }
    const results = await fhirClient.transactionRequest({ requests: [writeDocRefReq, ...additionalTasks] });
    const docRef = results.entry?.[0]?.resource;
    if (docRef?.resourceType !== 'DocumentReference') {
      throw 'failed';
    }
    return docRef;
  } catch (error: unknown) {
    throw new Error(`Failed to create DocumentReference resource: ${JSON.stringify(error)}`);
  }
}

interface DocRefSyncTaskInput {
  context: { patientId: string; encounterId?: string };
  documentRef: string;
  taskCoding: SyncTaskCoding;
}

export const makeSyncTask = (input: DocRefSyncTaskInput): Task => {
  const { context, documentRef, taskCoding: taskIndicator } = input;
  const { patientId, encounterId } = context;
  const base: Task = {
    resourceType: 'Task',
    code: { coding: [taskIndicator] },
    status: 'ready',
    intent: 'order',
    focus: { reference: documentRef },
    for: { reference: `Patient/${patientId}` },
  };
  if (encounterId) {
    base.encounter = { reference: `Encounter/${encounterId}` };
  }
  return base;
};

export interface TaskDetails {
  coding: UCTaskCoding;
  input?: TaskInput[];
}

interface AppointmentTaskInput {
  taskDetails: TaskDetails;
  appointmentID: string;
}

export const makeAppointmentTask = (input: AppointmentTaskInput): Task => {
  const { taskDetails, appointmentID } = input;
  return {
    resourceType: 'Task',
    status: 'requested',
    intent: 'plan',
    focus: {
      type: 'Appointment',
      reference: `Appointment/${appointmentID}`,
    },
    code: {
      coding: [taskDetails.coding],
    },
    input: taskDetails.input,
  };
};

export async function createConsentResource(
  patientID: string,
  documentReferenceID: string,
  dateTime: string,
  fhirClient: FhirClient,
  locationState: string
): Promise<Consent> {
  try {
    console.log('creating new consent resource');
    const consentUrl =
      locationState === 'IL' ? `CTT.and.Guarantee.of.Payment.Illinois-S.pdf` : `CTT.and.Guarantee.of.Payment-S.pdf`;
    const createdConsent = await fhirClient.createResource<Consent>({
      resourceType: 'Consent',
      dateTime: dateTime,
      status: 'active',
      patient: {
        reference: `Patient/${patientID}`,
      },
      category: [
        {
          coding: [
            {
              system: 'http://terminology.hl7.org/CodeSystem/consentcategorycodes',
              code: 'hipaa-ack',
            },
          ],
        },
        {
          coding: [
            {
              system: 'http://terminology.hl7.org/CodeSystem/consentcategorycodes',
              code: 'treat-guarantee',
            },
          ],
        },
      ],
      policy: [
        {
          uri: 'https://welcome.pmpediatriccare.com/HIPAA.Acknowledgement-S.pdf',
        },
        {
          uri: `https://welcome.pmpediatriccare.com/${consentUrl}`,
        },
      ],
      sourceReference: {
        reference: `DocumentReference/${documentReferenceID}`,
      },
      scope: {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/consentscope',
            code: 'patient-privacy',
            display: 'Privacy Consent',
          },
          {
            system: 'http://terminology.hl7.org/CodeSystem/consentscope',
            code: 'treatment',
            display: 'Treatment',
          },
        ],
      },
    });

    return createdConsent;
  } catch (error: unknown) {
    throw new Error(`Failed to create Consent resource: ${error}`);
  }
}

type MightHaveTelecom = RelatedPerson | Patient | Person | Practitioner;
export const getSMSNumberForIndividual = (individual: MightHaveTelecom): string | undefined => {
  const { telecom } = individual;
  return (telecom ?? []).find((cp) => {
    // format starts with +1; this is some lazy but probably good enough validation
    return cp.system === 'sms' && cp.value?.startsWith('+');
  })?.value;
};

export const getPhoneNumberForIndividual = (individual: MightHaveTelecom): string | undefined => {
  const { telecom } = individual;
  return (telecom ?? []).find((cp) => {
    // format starts with +1; this is some lazy but probably good enough validation
    return cp.system === 'phone' && cp.value;
  })?.value;
};

export const getWorkPhoneNumberForIndividual = (individual: MightHaveTelecom): string | undefined => {
  const { telecom } = individual;
  return (telecom ?? []).find((cp) => {
    return cp.system === 'phone' && cp.use === 'work';
  })?.value;
};

export const getEmailForIndividual = (individual: MightHaveTelecom): string | undefined => {
  const { telecom } = individual;
  return (telecom ?? []).find((cp) => {
    return cp.system === 'email';
  })?.value;
};

export const getWorkEmailForIndividual = (individual: MightHaveTelecom): string | undefined => {
  const { telecom } = individual;
  return (telecom ?? []).find((cp) => {
    return cp.system === 'email' && cp.use === 'work';
  })?.value;
};

export const getAddressForIndividual = (individual: MightHaveTelecom): Address | undefined => {
  const { address } = individual;
  return (address ?? []).find((address) => {
    // format starts with +1; this is some lazy but probably good enough validation
    return !address.period?.end;
  });
};

//FHIR_EXTENSION.Patient.formUser.url

// todo: this can probably be deleted
export const getFormUser = (patient: Patient): UserType | undefined => {
  const extensions = patient.extension ?? [];

  const stringVal = extensions.find((ext) => {
    return ext.url == FHIR_EXTENSION.Patient.formUser.url;
  })?.valueString;

  if (stringVal) {
    return stringVal as UserType;
  }
  return undefined;
};

interface ContactInfoPhoneNumebr {
  type: UserType;
  key: 'patient-email' | 'guardian-email';
  value: string;
}

export const getContactInfoSuppliedEmailForPatient = (patient: Patient): ContactInfoPhoneNumebr | undefined => {
  const formUser = getFormUser(patient);
  let value: string | undefined;
  let key: 'patient-email' | 'guardian-email' | undefined;

  if (formUser && formUser === 'Parent/Guardian') {
    key = 'guardian-email';
    const contacts = patient.contact ?? [];
    const contactToUse = contacts.find((cont) => {
      return cont.relationship?.find((relationship) => {
        return relationship?.coding?.[0].code === 'Parent/Guardian';
      });
    });
    if (contactToUse) {
      value = contactToUse.telecom?.find((tc) => tc.system === 'email')?.value;
    }
  } else if (formUser) {
    key = 'patient-email';
    const telecom = patient.telecom ?? [];
    const emailTel = telecom.find((tc) => {
      tc.system;
    });
    if (emailTel) {
      value = emailTel.value;
    }
  }
  if (value && formUser && key) {
    return {
      type: formUser,
      value,
      key,
    };
  } else {
    return undefined;
  }
};

export const getUnconfirmedDOBForAppointment = (appointment: Appointment): string | undefined => {
  const extensions = appointment.extension ?? [];
  return extensions.find((ext) => {
    return ext.url.replace('http:', 'https:') === FHIR_EXTENSION.Appointment.unconfirmedDateOfBirth.url;
  })?.valueString;
};

export const getLastUpdateTimestampForResource = (resource: Resource): number | undefined => {
  if (!resource) {
    return undefined;
  }
  const metaTimeStamp = resource.meta?.lastUpdated;

  if (metaTimeStamp) {
    const updateTime = DateTime.fromISO(metaTimeStamp);

    if (updateTime.isValid) {
      return updateTime.toSeconds();
    }
  }
  return undefined;
};

export async function getQuestionnaireResponse(
  questionnaireID: string,
  encounterID: string,
  fhirClient: FhirClient
): Promise<QuestionnaireResponse | undefined> {
  const questionnaireResponse: QuestionnaireResponse[] = await fhirClient.searchResources({
    resourceType: 'QuestionnaireResponse',
    searchParams: [
      {
        name: 'questionnaire',
        value: `Questionnaire/${questionnaireID}`,
      },
      {
        name: 'encounter',
        value: `Encounter/${encounterID}`,
      },
    ],
  });

  if (questionnaireResponse.length === 1) {
    return questionnaireResponse[0];
  }
  return undefined;
}

export async function getRecentQuestionnaireResponse(
  questionnaireID: string,
  patientID: string,
  fhirClient: FhirClient
): Promise<QuestionnaireResponse | undefined> {
  console.log('questionnaireID', questionnaireID);
  const questionnaireResponse: QuestionnaireResponse[] = await fhirClient.searchResources({
    resourceType: 'QuestionnaireResponse',
    searchParams: [
      {
        name: 'questionnaire',
        value: `Questionnaire/${questionnaireID}`,
      },
      {
        name: 'subject',
        value: `Patient/${patientID}`,
      },
      {
        name: 'source:missing',
        value: 'false',
      },
      {
        name: '_sort',
        value: '-_lastUpdated',
      },
      {
        name: '_count',
        value: '1',
      },
    ],
  });

  console.log('questionnaireResponse found', questionnaireResponse);

  if (questionnaireResponse.length === 1) {
    return questionnaireResponse[0];
  }
  return undefined;
}

export const CRITICAL_CHANGE_SYSTEM = 'critical-update-by'; // exists in ehr as well

export const getCriticalUpdateTagOp = (resource: Resource, updateBy: string): Operation => {
  const recordUpdateByTag = {
    system: CRITICAL_CHANGE_SYSTEM,
    display: updateBy,
    version: DateTime.now().toISO() || '',
  };

  if (!resource.meta?.tag) {
    return {
      op: 'add',
      path: '/meta/tag',
      value: [recordUpdateByTag],
    };
  } else {
    const currentTags = resource.meta?.tag ?? [];
    const existingTagIdx = currentTags.findIndex((coding) => {
      return coding.system === CRITICAL_CHANGE_SYSTEM;
    });
    if (existingTagIdx >= 0) {
      return {
        op: 'replace',
        path: `/meta/tag/${existingTagIdx}`,
        value: recordUpdateByTag,
      };
    } else {
      return {
        op: 'add',
        path: `/meta/tag/-`,
        value: recordUpdateByTag,
      };
    }
  }
};

export const getLocationIdFromAppointment = (appointment: Appointment): string | undefined => {
  return appointment.participant
    .find((appointment) => appointment.actor?.reference?.startsWith('Location/'))
    ?.actor?.reference?.replace('Location/', '');
};

export const getAbbreviationFromLocation = (location: Location): string | undefined => {
  return location.address?.state;
};

export function getTaskResource(coding: UCTaskCoding, appointmentID: string): Task {
  return {
    resourceType: 'Task',
    status: 'requested',
    intent: 'plan',
    focus: {
      type: 'Appointment',
      reference: `Appointment/${appointmentID}`,
    },
    code: {
      coding: [coding],
    },
  };
}
export const getStartTimeFromEncounterStatusHistory = (encounter: Encounter): string | undefined => {
  const statusHistory = encounter.statusHistory ?? [];

  return statusHistory.find((sh) => {
    return sh.status === 'arrived';
  })?.period?.start;
};

export function allLicensesForPractitioner(practitioner: Practitioner): PractitionerLicense[] {
  const allLicenses: PractitionerLicense[] = [];
  if (practitioner?.qualification) {
    practitioner.qualification.forEach((qualification) => {
      const qualificationExt = qualification.extension?.find(
        (ext) => ext.url === PRACTITIONER_QUALIFICATION_EXTENSION_URL
      );

      if (qualificationExt) {
        const qualificationCode = qualification.code.coding?.find(
          (code) => code.system === PRACTITIONER_QUALIFICATION_CODE_SYSTEM
        )?.code as PractitionerQualificationCode;

        const stateExtension = qualificationExt.extension?.find((ext) => ext.url === 'whereValid');
        const qualificationState = stateExtension?.valueCodeableConcept?.coding?.find(
          (coding) => coding.system === PRACTITIONER_QUALIFICATION_STATE_SYSTEM
        )?.code;

        if (qualificationCode && qualificationState)
          allLicenses.push({ state: qualificationState, code: qualificationCode });
      }
    });
  }

  return allLicenses;
}

export const getPractitionerStateCredentials = (practioner: Practitioner): string[] => {
  return allLicensesForPractitioner(practioner).map(({ state }) => state);
};

export const getPlanIdAndNameFromCoverage = (coverage: Coverage): { planId?: string; planName?: string } => {
  const coverageClass = coverage.class?.find((cc) => {
    const typeCoding = cc.type;
    const isPlan = typeCoding.coding?.some((coded) => {
      return coded.code === 'plan';
    });
    return cc.value && isPlan;
  });

  if (!coverageClass) {
    return {};
  }
  const { value: planId, name: planName } = coverageClass;
  return { planId, planName };
};

export const getGroupNumberAndNameFromCoverage = (coverage: Coverage): { groupNumber?: string; groupName?: string } => {
  const coverageClass = coverage.class?.find((cc) => {
    const typeCoding = cc.type;
    const isGroup = typeCoding.coding?.some((coded) => {
      return coded.code === 'group';
    });
    return cc.value && isGroup;
  });

  if (!coverageClass) {
    return {};
  }
  const { value: groupNumber, name: groupName } = coverageClass;
  return { groupNumber, groupName };
};

export const getStartAndEndDatesForCoverage = (coverage: Coverage): { start?: string; end?: string } => {
  const start = coverage.period?.start;
  const end = coverage.period?.end;

  return { start, end };
};

export interface PatientContact {
  relationship?: CodeableConcept[];
  name?: HumanName;
  telecom?: ContactPoint[];
  address?: Address;
  gender?: 'male' | 'female' | 'other' | 'unknown';
  period?: Period;
  organization?: Reference;
}
export const getBillingContactFromPatient = (patient: Patient): PatientContact | undefined => {
  return patient?.contact?.find((pc) => {
    if (pc.period?.end !== undefined) {
      return false;
    }
    return (
      pc.relationship?.some((rel) => {
        return (
          rel.coding?.some((coded) => {
            return coded.code === 'BP' && coded.system === 'http://terminology.hl7.org/CodeSystem/v2-013';
          }) ?? false
        );
      }) ?? false
    );
  });
};
